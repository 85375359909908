import React from "react";

const PlayActionIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_15579376"
    viewBox="0 0 32 32"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill-rule="evenodd">
      <path
        fill={color}
        d="m5 30.5c1.9296875 0 3.5-1.5703125 3.5-3.5s-1.5703125-3.5-3.5-3.5-3.5 1.5703125-3.5 3.5 1.5703125 3.5 3.5 3.5zm0-6c1.3789062 0 2.5 1.121582 2.5 2.5s-1.1210938 2.5-2.5 2.5-2.5-1.121582-2.5-2.5 1.1210938-2.5 2.5-2.5z"
      ></path>
      <path
        fill={color}
        d="m5 8.5c1.9296875 0 3.5-1.5703125 3.5-3.5s-1.5703125-3.5-3.5-3.5-3.5 1.5703125-3.5 3.5 1.5703125 3.5 3.5 3.5zm0-6c1.3789062 0 2.5 1.121582 2.5 2.5s-1.1210938 2.5-2.5 2.5-2.5-1.121582-2.5-2.5 1.1210938-2.5 2.5-2.5z"
      ></path>
      <path
        fill={color}
        d="m17.5 27c0 1.9296875 1.5703125 3.5 3.5 3.5s3.5-1.5703125 3.5-3.5c0-.1245117-.0107422-.25-.0214844-.3745117-.0253906-.2758789-.2861328-.480957-.5419922-.4536133-.2753906.0244141-.4785156.2670898-.4541016.5424805.0087891.0952148.0175781.1904297.0175781.2856445 0 1.378418-1.1210938 2.5-2.5 2.5s-2.5-1.121582-2.5-2.5c0-1.4731445 1.2978516-2.6176758 2.7841797-2.4819336.2685547.0283203.5175781-.1762695.5439453-.4516602.0253906-.2749023-.1767578-.5185547-.4521484-.5444336-.125-.0117188-.25-.0219727-.3759766-.0219727-1.9296875 0-3.5 1.5703125-3.5 3.5z"
      ></path>
      <path
        fill={color}
        d="m26 27.5h2.7929688l-.1464844.1464844c-.1953125.1953125-.1953125.5117188 0 .7070312.0976562.0976562.2255859.1464844.3535156.1464844s.2558594-.0488281.3535156-.1464844l1-1c.1953125-.1953125.1953125-.5117188 0-.7070312l-1-1c-.1953125-.1953125-.5117188-.1953125-.7070312 0s-.1953125.5117188 0 .7070312l.1464844.1464844h-2.7929688c-.2763672 0-.5.2236328-.5.5s.2236328.5.5.5z"
      ></path>
      <path
        fill={color}
        d="m17.5 12c0 1.9296875 1.5703125 3.5 3.5 3.5s3.5-1.5703125 3.5-3.5-1.5703125-3.5-3.5-3.5-3.5 1.5703125-3.5 3.5zm6 0c0 1.378418-1.1210938 2.5-2.5 2.5s-2.5-1.121582-2.5-2.5 1.1210938-2.5 2.5-2.5 2.5 1.121582 2.5 2.5z"
      ></path>
      <path
        fill={color}
        d="m10.6464844 6.3535156c.0976562.0976562.2255859.1464844.3535156.1464844s.2558594-.0488281.3535156-.1464844c.1953125-.1953125.1953125-.5117188 0-.7070312l-.1464844-.1464844h8.7929688c.2753906 0 .5.2241211.5.5v1c0 .2763672.2236328.5.5.5s.5-.2236328.5-.5v-1c0-.8271484-.6728516-1.5-1.5-1.5h-8.7929688l.1464844-.1464844c.1953125-.1953125.1953125-.5117188 0-.7070312s-.5117188-.1953125-.7070312 0l-1 1c-.1953125.1953125-.1953125.5117188 0 .7070312z"
      ></path>
      <path
        fill={color}
        d="m21 22.5c.2763672 0 .5-.2236328.5-.5v-3.7929688l.1464844.1464844c.0976562.0976562.2255859.1464844.3535156.1464844s.2558594-.0488281.3535156-.1464844c.1953125-.1953125.1953125-.5117188 0-.7070312l-1-1c-.1953125-.1953125-.5117188-.1953125-.7070312 0l-1 1c-.1953125.1953125-.1953125.5117188 0 .7070312s.5117188.1953125.7070312 0l.1464844-.1464844v3.7929688c0 .2763672.2236328.5.5.5z"
      ></path>
      <path
        fill={color}
        d="m10 27.5h4.7929688l-.1464844.1464844c-.1953125.1953125-.1953125.5117188 0 .7070312.0976562.0976562.2255859.1464844.3535156.1464844s.2558594-.0488281.3535156-.1464844l1-1c.1953125-.1953125.1953125-.5117188 0-.7070312l-1-1c-.1953125-.1953125-.5117188-.1953125-.7070312 0s-.1953125.5117188 0 .7070312l.1464844.1464844h-4.7929688c-.2763672 0-.5.2236328-.5.5s.2236328.5.5.5z"
      ></path>
      <path
        fill={color}
        d="m5 9.5c-.2763672 0-.5.2236328-.5.5v10.7929688l-.1464844-.1464844c-.1953125-.1953125-.5117188-.1953125-.7070312 0s-.1953125.5117188 0 .7070312l1 1c.0976562.0976562.2255859.1464844.3535156.1464844s.2558594-.0488281.3535156-.1464844l1-1c.1953125-.1953125.1953125-.5117188 0-.7070312s-.5117188-.1953125-.7070312 0l-.1464844.1464844v-10.7929688c0-.2763672-.2236328-.5-.5-.5z"
      ></path>
      <path
        fill={color}
        d="m23.4748535 25.2322388c.1953125-.1953125.1953125-.5118408 0-.7071533-.1952515-.1952515-.5118408-.1952515-.7070923 0-.1952515.1953125-.1952515.5118408 0 .7071533.1952515.1952515.5118408.1952515.7070923 0z"
      ></path>
    </g>
  </svg>
);

export default PlayActionIcon;
