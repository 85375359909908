import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import MeetingsComponent from "../../components/PortalComponents/MeetingsComponents/MeetingsComponent";

const MeetingsPage = () => {
  return (
    <>
      <Sidebar childComponent={<MeetingsComponent />} activeText="Meetings" />
    </>
  );
};

export default MeetingsPage;
