import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import Playbook from "../../components/PortalComponents/PlaybookComponents/PlayBook";

const PlaybookPage = () => {
  return (
    <>
      <Sidebar childComponent={<Playbook />} activeText="Playbook" />
    </>
  );
};

export default PlaybookPage;
