import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { auth } from "../services/firebase";

import { db } from "../services/firebase"; // Import db separately
import { storage } from "../services/firebase"; // Import storage separately

import { default_data } from "../components/PortalComponents/CreatorSearchComponents/default_data";

import {
  query,
  orderBy,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  where,
  writeBatch,
  onSnapshot,
  deleteDoc,
  runTransaction,
  limit,
  addDoc,
} from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [loading, setIsLoading] = useState(null);
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [planType, setPlanType] = useState();

  const [skipUpdate, setSkipUpdate] = useState(null);

  const [sidebarNotifications, setSidebarNotifications] = useState({
    inbox: 0,
    settings: 0,
    disconnected_emails: 0,
  });

  const [currentFilters, setCurrentFilters] = useState();

  useEffect(() => {
    const storedUser = localStorage.getItem("authUser");
    if (storedUser) {
      const user = JSON.parse(storedUser);
      localStorage.setItem("USER_ID", user.uid);
      setCurrentUser(user);
      fetchUserData(user.uid);
    }

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(user);
        localStorage.setItem("authUser", JSON.stringify(user));
        fetchUserData(user.uid);
      } else {
        setCurrentUser(null);
        setProfile(null);
        localStorage.removeItem("authUser");
      }
    });

    return () => unsubscribe(); // Clean up the subscription
  }, []);

  const fetchUserData = async (userId) => {
    setIsLoading(true);
    try {
      const userDocRef = doc(db, "Accounts", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        let userData = userDoc.data();

        // await fetchCustomerInformation(userData.stripe_id);

        if (
          userData?.recent_search_information?.contact_search_results &&
          typeof userData.recent_search_information.contact_search_results ===
            "string"
        ) {
          userData.recent_search_information.contact_search_results =
            JSON.parse(
              userData.recent_search_information.contact_search_results
            );
        }

        if (
          userData?.recent_search_information?.search_filters &&
          typeof userData.recent_search_information.search_filters === "string"
        ) {
          userData.recent_search_information.search_filters = JSON.parse(
            userData.recent_search_information.search_filters
          );
        }

        const connectedAccountsRef = collection(
          userDocRef,
          "connected_accounts"
        );

        const snapshot = await getDocs(connectedAccountsRef);
        userData.connected_accounts = snapshot.docs.map((doc) => doc.data());

        setProfile(userData);

        if (userData.recent_search_information.search_filters) {
          setCurrentFilters(userData.recent_search_information.search_filters);
        }
        if (userData.paymentInfo) {
          setPlanType(userData.paymentInfo.planType);
        }

        const disconnectedAccountsCount = userData.connected_accounts.filter(
          (account) => account.disconnected === true
        ).length;

        setSidebarNotifications((prevNotifications) => ({
          ...prevNotifications,
          inbox: userData?.unread_threads,
          disconnected_emails: disconnectedAccountsCount,
        }));

        setIsLoading(false);
      } else {
        console.warn("No data found for user:", userId);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setIsLoading(false);
    }
  };

  const signIn = async (email, password) => {
    try {
      const credential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setCurrentUser(credential.user);
      localStorage.setItem("authUser", JSON.stringify(credential.user));
      fetchUserData(credential.user.uid);

      return credential;
    } catch (error) {
      setError(error.message);
      throw error;
    }
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      console.log("Password reset email sent successfully");
      return true;
    } catch (error) {
      console.error("Error sending password reset email:", error);
      return false;
    }
  };

  const databaseRegister = async (formData) => {
    try {
      const accountsRef = collection(db, "Accounts");
      const existingUsersQuery = query(
        accountsRef,
        where("email", "==", formData.email)
      );
      const querySnapshot = await getDocs(existingUsersQuery);

      let userDocRef;

      if (!querySnapshot.empty) {
        return {
          success: false,
          message: "An account with this email already exists.",
        };
      } else {
        const registeredUsersRef = collection(db, "registered");
        userDocRef = doc(registeredUsersRef);

        await setDoc(userDocRef, {
          uid: userDocRef.id,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          converted: false,
          timestamp: new Date(),
        });
      }

      return {
        success: true,
        message: "Account registered successfully.",
        register_id: userDocRef.id,
      };
    } catch (error) {
      console.error("Error handling user registration:", error);
      throw error;
    }
  };

  const addRegistrationInformation = async (formData) => {
    try {
      const registeredUsersRef = doc(db, "registered", formData.register_id);

      await updateDoc(registeredUsersRef, {
        companyName: formData.companyName,
        companyWebsite: formData.companyWebsite,
        position: formData.position,
        companySize: formData.companySize,
      });

      return {
        success: true,
        message: "Company information added to registration successfully.",
      };
    } catch (error) {
      console.error("Error updating registration information:", error);
      throw error;
    }
  };

  const addAdditionalRegistrationInformation = async (formData) => {
    try {
      const registeredUsersRef = doc(db, "registered", formData.register_id);

      await updateDoc(registeredUsersRef, {
        reasonForUse: formData.reasonForUse,
        industry: formData.industry,
        hearAboutUs: formData.hearAboutUs,
      });

      return {
        success: true,
        message: "Company information added to registration successfully.",
      };
    } catch (error) {
      console.error("Error updating registration information:", error);
      throw error;
    }
  };

  const markAsConverted = async (formData) => {
    try {
      const registeredUsersRef = doc(db, "registered", formData.register_id);
      await updateDoc(registeredUsersRef, {
        converted: true,
      });
    } catch (error) {
      console.error("Error marking registration as converted:", error);
      throw error;
    }
  };

  const createAccount = async (formData, stripe_id) => {
    setCreatingAccount(true);
    try {
      const { password, ...userData } = formData;

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        password
      );
      const user = userCredential.user;

      const accountsRef = collection(db, "Accounts");

      await setDoc(doc(accountsRef, user.uid), {
        uid: user.uid,
        ...userData,
        email: formData.email,
        stripe_id: stripe_id || formData.stripe_id,
      });

      await saveDefaultSearch(user.uid, default_data.data);
      await markAsConverted(formData);

      setCurrentUser(user);
      localStorage.setItem("authUser", JSON.stringify(user));

      await fetchUserData(user.uid);

      const serverResponse = await axios.post(
        "https://brandlink-24432.uc.r.appspot.com/account/reset-account-limits",
        {
          userId: user.uid,
        }
      );

      console.log("Server response:", serverResponse.data);
      setCreatingAccount(false);
      return userCredential;
    } catch (error) {
      setCreatingAccount(false);
      console.error("Account creation error:", error);
      throw error;
    }
  };

  async function saveDefaultSearch(userId, contactSearchResults) {
    try {
      const userDocRef = doc(db, "Accounts", userId);
      const contactSearchResultsString = JSON.stringify(contactSearchResults);

      await setDoc(
        userDocRef,
        {
          recent_search_information: {
            contact_search_results: contactSearchResultsString,
          },
        },
        { merge: true }
      );

      console.log("Filter options saved successfully");
    } catch (error) {
      console.error("Error saving filter options: ", error);
    }
  }

  const signInWithGoogle = async () => {
    const googleProvider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      console.log("Google Sign-In successful, User:", user);
    } catch (error) {
      console.error("Error signing in with Google:", error.message);
    }
  };

  const updateUserProfile = async (userId, updatedProfile) => {
    try {
      const { recent_search_information, ...fieldsToUpdate } = updatedProfile;

      const userDocRef = doc(db, "Accounts", userId);
      await updateDoc(userDocRef, fieldsToUpdate);

      setProfile((prevProfile) => ({
        ...prevProfile,
        ...updatedProfile,
      }));

      console.log("Profile updated successfully.");
    } catch (error) {
      console.error("Error updating user profile:", error);
      setError(error.message);
      throw error;
    }
  };

  const fetchUserContacts = async (
    userId,
    searchTerm,
    sortOption = "Date Added"
  ) => {
    try {
      const contactsCollectionRef = collection(
        db,
        "Accounts",
        userId,
        "contacts"
      );

      let queryContacts;
      const lowerCaseSearchTerm = searchTerm ? searchTerm.toLowerCase() : null;
      const endAtTerm = lowerCaseSearchTerm
        ? lowerCaseSearchTerm + "\uf8ff"
        : null;

      const sortFieldMap = {
        "Date Added": "createdAt",
        "Average Likes": "average_likes",
        "Engagement Rate": "engagement_rate",
        Followers: "follower_count",
      };

      const sortField = sortFieldMap[sortOption] || "createdAt";

      if (searchTerm) {
        queryContacts = query(
          contactsCollectionRef,
          where("contact_search_name", ">=", lowerCaseSearchTerm),
          where("contact_search_name", "<=", endAtTerm),
          // orderBy(sortField, "desc"),
          limit(25)
        );
      } else {
        queryContacts = query(
          contactsCollectionRef,
          orderBy(sortField, "desc"),
          limit(25)
        );
      }

      const querySnapshot = await getDocs(queryContacts);
      const contacts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log("Contacts fetched successfully.");
      return contacts;
    } catch (error) {
      console.error("Error fetching contacts:", error);
      throw error;
    }
  };

  const addToContacts = async (contact) => {
    try {
      const contactsCollectionRef = collection(
        db,
        "Accounts",
        profile.uid,
        "contacts"
      );

      const contactDocRef = doc(contactsCollectionRef, contact.external_id);

      const timestampedContact = {
        ...contact,
        contact_search_name: contact.full_name?.toLowerCase() ?? "",
        createdAt: Date.now(),
      };

      await setDoc(contactDocRef, timestampedContact);
    } catch (error) {
      console.error("Error adding contact with timestamp:", error);
      setError(error.message);
      throw error;
    }
  };

  const removeFromContacts = async (contact) => {
    try {
      const contactsCollectionRef = collection(
        db,
        "Accounts",
        profile.uid,
        "contacts"
      );

      const contactDocRef = doc(contactsCollectionRef, contact.external_id);

      await deleteDoc(contactDocRef);

      console.log("Contact removed successfully.");
    } catch (error) {
      console.error("Error removing contact:", error);
      setError(error.message);
      throw error;
    }
  };

  const addWaitlistUser = async (email, name, companySite) => {
    const waitlistRef = collection(db, "waitlist");

    try {
      const newDocRef = doc(waitlistRef);
      await setDoc(newDocRef, {
        name: name,
        email: email,
        companySite: companySite,
      });
      console.log("User added to waitlist successfully");
    } catch (error) {
      console.error("Error adding user to waitlist:", error);
    }
  };

  const updateContactsFetchedCount = async (contactsFetched) => {
    const userProfileRef = doc(db, "Accounts", profile.uid);

    return runTransaction(db, async (transaction) => {
      const userProfileDoc = await transaction.get(userProfileRef);

      if (!userProfileDoc.exists()) {
        throw new Error("Document does not exist!");
      }

      // Fetching existing values with default fallback
      const currentContactsSearched =
        userProfileDoc.data().contacts_searched || 0;
      const totalCurrentContactsSearched =
        userProfileDoc.data().total_contacts_searched || 0;

      // Calculating new values
      const updatedContactsSearched = currentContactsSearched + contactsFetched;
      const updatedTotalContactsSearched =
        totalCurrentContactsSearched + contactsFetched;

      // Updating the Firestore document
      transaction.update(userProfileRef, {
        contacts_searched: updatedContactsSearched,
        total_contacts_searched: updatedTotalContactsSearched,
      });

      setSkipUpdate(true);

      // Update the local state after a successful transaction
      setProfile((prevProfile) => ({
        ...prevProfile,
        contacts_searched: updatedContactsSearched,
        total_contacts_searched: updatedTotalContactsSearched, // Add this if you want to update it locally too
      }));
    });
  };

  const updateLastResultsCount = async (totalResults) => {
    const userProfileRef = doc(db, "Accounts", profile.uid);

    try {
      await updateDoc(userProfileRef, {
        last_results_count: totalResults,
      });

      setSkipUpdate(true);

      setProfile((prevProfile) => ({
        ...prevProfile,
        last_results_count: totalResults,
      }));

      console.log("Last results count and profile state updated successfully");
    } catch (error) {
      console.error("Error updating last results count:", error);
    }
  };

  const updateAccountProfilePicture = async (userId, file) => {
    try {
      const storageRef = ref(storage, `profile_pictures/${userId}`);

      await uploadBytes(storageRef, file);

      const downloadURL = await getDownloadURL(storageRef);

      const userDocRef = doc(db, "Accounts", userId);
      await updateDoc(userDocRef, {
        profilePicture: downloadURL,
      });

      setProfile((prevProfile) => ({
        ...prevProfile,
        profilePicture: downloadURL,
      }));

      console.log("Profile picture updated successfully.");
    } catch (error) {
      console.error("Error updating profile picture:", error);
    }
  };

  async function generateAiReply(systemPrompt, temperature) {
    const endpoint =
      "https://brandlink-24432.uc.r.appspot.com/ai/generate-ai-reply";
    const data = {
      userId: profile.uid,
      systemPrompt: systemPrompt,
      temperature: temperature,
    };

    try {
      const response = await axios.post(endpoint, data);
      console.log("AI Reply Fetched:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching AI reply:",
        error.response ? error.response.data : error.message
      );
    }
  }

  const logout = async () => {
    try {
      await auth.signOut();
      setCurrentUser(null);
      setProfile(null);
      localStorage.removeItem("authUser");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const updateUserEmail = async (newEmail, setProfile) => {
    try {
      const user = auth.currentUser;

      if (!user) {
        throw new Error("No user is currently signed in.");
      }

      await user.updateEmail(newEmail);

      const userDocRef = doc(db, "Accounts", user.uid);

      await updateDoc(userDocRef, { email: newEmail });

      setProfile((prevProfile) => ({
        ...prevProfile,
        email: newEmail,
      }));

      console.log("Email updated successfully and Firestore updated");
      return user;
    } catch (error) {
      console.error("Error updating email:", error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        createAccount,
        signInWithGoogle,
        resetPassword,
        profile,
        setProfile,
        planType,
        updateUserProfile,
        error,
        loading,
        creatingAccount,
        addWaitlistUser,
        currentUser,
        updateAccountProfilePicture,
        logout,
        currentFilters,
        databaseRegister,
        addRegistrationInformation,
        addAdditionalRegistrationInformation,
        addToContacts,
        fetchUserContacts,
        generateAiReply,
        updateContactsFetchedCount,
        addWaitlistUser,
        updateUserEmail,
        sidebarNotifications,
        setSidebarNotifications,
        removeFromContacts,
        skipUpdate,
        setSkipUpdate,
        updateLastResultsCount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

//Instagram Data, 3 Connected Mailboxes, 2048 Ai Credits, 1000 Filtered Contacts
// async function countDocumentsInCollection() {
//     const coll = collection(db, "locations"); // Replace "locations" with your collection name
//     const snapshot = await getCountFromServer(coll);
//     console.log("Total count:", snapshot.data().count);
// //   }
