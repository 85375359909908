import React from "react";

const JoinMeetingActionIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_4435972"
    height={height}
    viewBox="0 0 64 64"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
  >
    <path
      fill={color}
      d="m47 56h-42a3 3 0 0 1 -3-3v-41a3 3 0 0 1 3-3h42a3 3 0 0 1 3 3v41a3 3 0 0 1 -3 3zm-42-45a1 1 0 0 0 -1 1v41a1 1 0 0 0 1 1h42a1 1 0 0 0 1-1v-41a1 1 0 0 0 -1-1z"
    ></path>
    <path
      fill={color}
      d="m17.681 61.193a3 3 0 0 1 -2.957-2.5 1 1 0 0 1 1.972-.332 1 1 0 0 0 1.152.821l41.422-6.954a1 1 0 0 0 .82-1.152l-6.79-40.435a1 1 0 0 0 -1.152-.82 1 1 0 0 1 -.332-1.973 3.005 3.005 0 0 1 3.456 2.462l6.787 40.435a3.005 3.005 0 0 1 -2.459 3.455l-41.421 6.952a3.014 3.014 0 0 1 -.498.041z"
    ></path>
    <path
      fill={color}
      d="m41.105 19.677a3 3 0 0 1 -2.929-2.383l-1.03-4.894a1 1 0 0 1 1.957-.412l1.03 4.893a1 1 0 1 0 1.957-.412l-2.06-9.784a2 2 0 1 0 -3.914.823 1 1 0 1 1 -1.957.413 4 4 0 1 1 7.828-1.649l2.06 9.786a3 3 0 0 1 -2.942 3.619z"
    ></path>
  </svg>
);

export default JoinMeetingActionIcon;
