import React from "react";

const LocationIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    version="1.1"
    id="fi_819865"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 511.999 511.999"
  >
    <g>
      <g>
        <path
          fill={color}
          d="M256.98,40.005c-5.53-0.04-10.04,4.41-10.08,9.93c-0.04,5.52,4.4,10.03,9.93,10.07c5.52,0.04,10.03-4.4,10.07-9.92
			C266.94,44.565,262.5,40.055,256.98,40.005z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          fill={color}
          d="M256.525,100.004c-38.611-0.274-70.232,30.875-70.524,69.474c-0.291,38.597,30.875,70.233,69.474,70.524
			c0.179,0.001,0.357,0.002,0.536,0.002c38.353,0,69.698-31.056,69.988-69.476C326.29,131.933,295.125,100.295,256.525,100.004z
			 M256.009,220.005c-0.126,0-0.258-0.001-0.384-0.002c-27.571-0.208-49.833-22.806-49.625-50.375
			c0.207-27.445,22.595-49.627,49.991-49.627c0.126,0,0.258,0.001,0.384,0.002c27.571,0.208,49.833,22.806,49.625,50.375
			C305.792,197.823,283.405,220.005,256.009,220.005z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          fill={color}
          d="M299.631,47.589c-5.202-1.846-10.921,0.879-12.767,6.085c-1.845,5.206,0.88,10.921,6.085,12.767
			c44.047,15.611,73.402,57.562,73.05,104.389c-0.041,5.522,4.402,10.033,9.925,10.075c0.025,0,0.051,0,0.077,0
			c5.486,0,9.956-4.428,9.998-9.925C386.415,115.633,351.706,66.046,299.631,47.589z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          fill={color}
          d="M317.357,376.442c66.513-85.615,108.08-130.26,108.641-205.164C426.702,77.035,350.22,0,255.984,0
			C162.848,0,86.71,75.428,86.002,168.728c-0.572,76.935,41.767,121.519,108.739,207.7C128.116,386.384,86.002,411.401,86.002,442
			c0,20.497,18.946,38.89,53.349,51.79c31.313,11.742,72.74,18.209,116.649,18.209s85.336-6.467,116.649-18.209
			c34.403-12.901,53.349-31.294,53.349-51.791C425.998,411.417,383.923,386.406,317.357,376.442z M106.001,168.879
			C106.625,86.55,173.8,20,255.986,20c83.159,0,150.633,67.988,150.013,151.129c-0.532,71.134-44.614,114.971-114.991,206.714
			c-12.553,16.356-24.081,31.82-34.993,46.947c-10.88-15.136-22.178-30.323-34.919-46.953
			C147.81,282.253,105.459,241.729,106.001,168.879z M256,492c-85.851,0-149.999-26.397-149.999-50
			c0-17.504,38.348-39.616,102.826-47.273c14.253,18.701,26.749,35.691,39.005,53.043c1.872,2.65,4.914,4.228,8.159,4.23
			c0.003,0,0.006,0,0.009,0c3.242,0,6.283-1.572,8.158-4.217c12.14-17.126,24.978-34.535,39.109-53.045
			c64.418,7.665,102.732,29.77,102.732,47.263C405.998,465.603,341.851,492,256,492z"
        ></path>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default LocationIcon;
