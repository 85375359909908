const contacts_data = [
  {
    name: "Emily Smith",
    email: "emily.smith@example.com",
    phone: "(773)-393-5352",
    company: "Smith & Co.",
    role: "Marketing Manager",
    industry: "Advertising",
    location: "New York, NY",
    keywords: ["Marketing", "SEO", "Content Creation"],
    contact_image: "ArtemisDummyImages/contact2.jpg",
    company_image: "ArtemisDummyImages/logo2.png",
  },
  {
    name: "Michael Johnson",
    email: "michael.johnson@example.com",
    phone: "(773)-393-5352",
    company: "Johnson Enterprises",
    role: "Project Manager",
    industry: "Construction",
    location: "Dallas, TX",
    keywords: ["Project Management", "Scheduling", "Budgeting"],
    contact_image: "ArtemisDummyImages/contact3.webp",
    company_image: "ArtemisDummyImages/logo3.jpg",
  },
  {
    name: "Sophia Lee",
    email: "sophia.lee@example.com",
    phone: "(773)-393-5352",
    company: "Lee Designs",
    role: "Graphic Designer",
    industry: "Design",
    location: "Los Angeles, CA",
    keywords: ["Adobe Photoshop", "Illustrator", "InDesign"],
    contact_image: "ArtemisDummyImages/contact4.jpg",
    company_image: "ArtemisDummyImages/logo4.webp",
  },
  {
    name: "David Brown",
    email: "david.brown@example.com",
    phone: "(773)-393-5352",
    company: "Brown Financial",
    role: "Financial Analyst",
    industry: "Finance",
    location: "Chicago, IL",
    keywords: ["Financial Modeling", "Data Analysis", "Excel"],
    contact_image: "ArtemisDummyImages/contact5.jpg",
    company_image: "ArtemisDummyImages/logo5.png",
  },
  {
    name: "Olivia Martinez",
    email: "olivia.martinez@example.com",
    phone: "(773)-393-5352",
    company: "Martinez Pharmaceuticals",
    role: "Research Scientist",
    industry: "Pharmaceuticals",
    location: "Boston, MA",
    keywords: ["Research", "Lab Work", "Clinical Trials"],
    contact_image: "ArtemisDummyImages/contact6.webp",
    company_image: "ArtemisDummyImages/logo6.jpg",
  },
  {
    name: "James Davis",
    email: "james.davis@example.com",
    phone: "(773)-393-5352",
    company: "Davis Logistics",
    role: "Operations Manager",
    industry: "Logistics",
    location: "Atlanta, GA",
    keywords: ["Logistics", "Supply Chain", "Management"],
    contact_image: "ArtemisDummyImages/contact7.jpg",
    company_image: "ArtemisDummyImages/logo7.png",
  },
  {
    name: "Isabella Wilson",
    email: "isabella.wilson@example.com",
    phone: "(773)-393-5352",
    company: "Wilson Education",
    role: "Teacher",
    industry: "Education",
    location: "Seattle, WA",
    keywords: ["Teaching", "Curriculum Development", "Mentoring"],
    contact_image: "ArtemisDummyImages/contact8.jpg",
    company_image: "ArtemisDummyImages/logo8.jpg",
  },
  {
    name: "William Anderson",
    email: "william.anderson@example.com",
    phone: "(773)-393-5352",
    company: "Anderson Law Firm",
    role: "Attorney",
    industry: "Legal",
    location: "Denver, CO",
    keywords: ["Law", "Litigation", "Contracts"],
    contact_image: "ArtemisDummyImages/contact9.jpg",
    company_image: "ArtemisDummyImages/logo9.webp",
  },
  {
    name: "Mia Thomas",
    email: "mia.thomas@example.com",
    phone: "(773)-393-5352",
    company: "Thomas Hospitality",
    role: "Hotel Manager",
    industry: "Hospitality",
    location: "Miami, FL",
    keywords: ["Customer Service", "Event Planning", "Management"],
    contact_image: "ArtemisDummyImages/contact10.jpg",
    company_image: "ArtemisDummyImages/logo10.webp",
  },
  {
    name: "Alexander Garcia",
    email: "alexander.garcia@example.com",
    phone: "(773)-393-5352",
    company: "Garcia Automotive",
    role: "Mechanical Engineer",
    industry: "Automotive",
    location: "Detroit, MI",
    keywords: ["Engineering", "AutoCAD", "Mechanical Design"],
    contact_image: "ArtemisDummyImages/contact11.webp",
    company_image: "ArtemisDummyImages/logo11.jpg",
  },
  {
    name: "Charlotte Martinez",
    email: "charlotte.martinez@example.com",
    phone: "(773)-393-5352",
    company: "Martinez Consulting",
    role: "Business Consultant",
    industry: "Consulting",
    location: "Austin, TX",
    keywords: ["Business Strategy", "Analysis", "Consulting"],
    contact_image: "ArtemisDummyImages/contact12.jpg",
    company_image: "ArtemisDummyImages/logo12.jpg",
  },
  {
    name: "Justin Cose",
    email: "justinrcose@gmail.com",
    phone: "(773)-393-5352",
    company: "Cose Innovations",
    role: "Software Engineer",
    industry: "Technology",
    location: "San Francisco, CA",
    keywords: ["JavaScript", "React", "Node.js"],
    contact_image: "ArtemisDummyImages/contact1.jpg",
    company_image: "ArtemisDummyImages/logo1.png",
  },
];

export default contacts_data;
