import React from "react";

const CampaignIcon = ({
  width = 14,
  height = 14,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_4240655"
    enable-background="new 0 0 512 512"
    width={width}
    height={height}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      clip-rule="evenodd"
      d="m94.978 314.608c-50.344-.013-91.529-41.207-91.529-91.553 0-50.353 41.198-91.551 91.552-91.551l78.803.001c0 60.286 2.044 123.588 0 183.103zm79.987 34.659c1.581 15.183 4.636 30.224 9.413 44.58 7.229 21.726 18.882 43.024 36.025 58.513 3.299 2.982 4.595 7.163 3.731 11.504l-7.624 38.329c-.821 4.129-3.419 7.283-7.327 8.858-4.024 1.621-7.984 1.157-11.537-1.316-28.222-19.643-48.833-48.506-62.307-79.844-10.96-25.489-17.436-52.913-20.002-80.624zm33.498-221.819v190.499c97.408 21.202 178.413 79.376 255.813 127.615 9.37 5.841 21.144 2.162 30.229-3.846 9.086-6.008 14.046-14.978 14.046-25.412v-384.634c0-10.397-4.924-19.342-13.96-25.355-9.036-6.012-20.106-8.541-30.112-3.969-70.299 32.141-159.821 99.824-256.016 125.102z"
      fill-rule="evenodd"
    ></path>
  </svg>
);

export default CampaignIcon;
