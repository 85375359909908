import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./askartemis.module.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { ArtemisContext } from "../../../context/ArtemisContext";
import { ConfirmDeletePopup } from "../../PopUpComponents/DeletePopup/ConfirmDeletePopup";
import { toast } from "react-toastify";
import AskArtemisInput from "./AskArtemisInput";
import NewConversationPrompt from "./NewConversationPrompt";

export default function AskArtemis() {
  const { conversation, setConversation, sendArtemisMessage } =
    useContext(ArtemisContext);

  const [userInput, setUserInput] = useState("");

  const [deleteFunction, setDeleteFunction] = useState(() => () => {});
  const [deletePopup, setDeletePopup] = useState(false);

  const [loadingResponse, setLoadingResponse] = useState(false);

  const deleteMessageFunction = async (chatId, messageId) => {
    // Placeholder for message delete functionality
  };

  const handleDeleteClick = (messageId) => {
    // Setup delete function and display popup
    setDeleteFunction(() => () => deleteMessageFunction(null, messageId));
    setDeletePopup(true);
  };

  const handleSendMessage = async () => {
    const trimmedInput = userInput.trim();
    if (!trimmedInput) return;
    setLoadingResponse(true);
    setUserInput("");
    try {
      const userMessageId = await sendArtemisMessage(trimmedInput);
      if (!userMessageId) console.log("Error Saving User Message");
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLoadingResponse(false);
    }
  };

  const handleKeyPress = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      await handleSendMessage();
    }
  };

  return (
    <div className={styles.main_layout}>
      {conversation.length > 0 ? (
        <>
          <div className={styles.chat_container}>
            <MessageComponent
              conversation={conversation}
              onDeleteClick={handleDeleteClick}
              loadingResponse={loadingResponse}
            />
          </div>
        </>
      ) : (
        <div className={styles.no_chat_selected}>
          <NewConversationPrompt setUserInput={setUserInput} />
        </div>
      )}
      <div className={styles.ask_artemis_input_box_layout}>
        <AskArtemisInput
          userInput={userInput}
          setUserInput={setUserInput}
          handleKeyPress={handleKeyPress}
          handleSendMessage={handleSendMessage}
        />
      </div>
      <ConfirmDeletePopup
        openModal={deletePopup}
        closeModal={() => setDeletePopup(false)}
        actionFunction={deleteFunction}
        desc={"Are you sure you want to delete?"}
      />
    </div>
  );
}

function MessageComponent({ conversation, onDeleteClick, loadingResponse }) {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  useEffect(scrollToBottom, [conversation]);

  return (
    <div className={styles.message_wrapper}>
      {conversation.map((message, index) => (
        <Message
          key={index}
          message={message}
          onDeleteClick={onDeleteClick}
          index={index}
        />
      ))}
      {loadingResponse && <LoadingMessage />}
      <div ref={messagesEndRef} />
    </div>
  );
}

function Message({ message, onDeleteClick, index }) {
  const { profile } = useContext(AuthContext);

  const isSenderSelf = message.sender !== "Artemis";
  const messageLayout = isSenderSelf
    ? styles.message_layout_content_reversed
    : styles.message_layout_content;

  return (
    <div
      className={`${styles.message_container} ${
        isSenderSelf ? styles.reversed : ""
      }`}
    >
      <div
        className={`${styles.message_avatar} ${
          isSenderSelf
            ? styles.message_avatar_self
            : styles.message_avatar_other
        }`}
      >
        <img
          className={styles.message_avatar_image_icon}
          src={isSenderSelf ? profile?.profilePicture : "/ArtemisBug.png"}
          alt={isSenderSelf ? "Self" : "Other"}
        />
      </div>
      <div
        className={`${styles.message_content} ${
          isSenderSelf
            ? styles.self_message_content
            : styles.other_message_content
        }`}
      >
        <div
          className={`${styles.message_info} ${
            isSenderSelf ? styles.self_message_info : styles.other_message_info
          }`}
        >
          <p className={styles.message_sender}>
            {isSenderSelf ? "Justin" : "Artemis"}
          </p>
        </div>
        <div className={messageLayout}>
          <div
            className={`${styles.message_text_content} ${
              isSenderSelf
                ? styles.self_message_text_content
                : styles.other_message_text_content
            }`}
          >
            <p className={styles.message_text}>{message.content}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function LoadingMessage() {
  const isSenderSelf = false;
  return (
    <div
      className={`${styles.message_container} ${
        isSenderSelf ? styles.reversed : ""
      }`}
    >
      <div
        className={`${styles.message_avatar} ${
          isSenderSelf
            ? styles.message_avatar_self
            : styles.message_avatar_other
        }`}
      >
        <img
          className={styles.message_avatar_image}
          src="/ArtemisBug.png"
          alt="Other"
        />
      </div>

      <div
        className={`${styles.message_content} ${
          isSenderSelf
            ? styles.self_message_content
            : styles.other_message_content
        }`}
      >
        <div
          className={`${styles.message_info} ${
            isSenderSelf ? styles.self_message_info : styles.other_message_info
          }`}
        >
          <p className={styles.message_sender}>Artemis</p>
        </div>
        <div className={styles.message_layout_content}>
          <div className={styles.loading_message}>
            <div className={styles.loader}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function renderTextWithLineBreaks(text) {
  return text.split("<br>").map((line, index, array) => (
    <React.Fragment key={index}>
      {line}
      {/* Add two <br> elements after each line except the last one */}
      {index < array.length - 1 && (
        <>
          <br />
          <br />
        </>
      )}
    </React.Fragment>
  ));
}
