import { useState, useRef, useEffect } from "react";
import React from "react";
import styles from "./dashboard_new.module.css";

import FilledAreaChart from "../DashboardCharts/FilledAreaChart";
import CheckmarkIcon from "../../../../assets/SVG/CheckmarkIcon";
import ProgressCircle from "../DashboardCharts/ProgressCircle";

import CountryChart from "../DashboardCharts/CountryChart";

import quickstart_campaigns_data from "../../../../data/quickstart_campaigns_data";

import { meetings_data } from "../../../../data/meetings_data";

import PlayIcon from "../../../../assets/SVG/PlayIcon";
//
export default function NewDashboardContent() {
  const getting_started_data = [
    {
      id: "1",
      title: "Complete your business profile",
      subtitle: "Help Artemis Understand your business",
      icon: "i",
      complete: false,
    },
    {
      id: "1",
      title: "Connect your email",
      subtitle: "Connect your email to let Artemis run your outreach",
      icon: "i",
      complete: false,
    },
    {
      id: "1",
      title: "Have artemis build a contact list for you",
      subtitle: "Have Artemis build you a prospect list",
      icon: "i",
      complete: false,
    },
    {
      id: "1",
      title: "Build your first play",
      subtitle: "Create your first messaging sequence to run on prospects",
      icon: "i",
      complete: false,
    },
    {
      id: "1",
      title: "Start your first outreach campaign",
      subtitle: "Have Artemis start running your outreach",
      icon: "i",
      complete: false,
    },
  ];

  return (
    <div className={styles.dashboard_content}>
      <div className={styles.top_layouts}>
        <div className={styles.top_left_layouts}>
          <div className={styles.long_tile}>
            <div className={styles.demo_left}>
              <p className={styles.demo_title}>New</p>
              <p className={styles.demo_subtitle}>
                Create and manage your own scheduling link within Artemis
              </p>
              <div className={styles.demo_cta}>Try Now</div>
            </div>
            <div className={styles.demo_right}>
              <img
                className={styles.new_demo_image}
                src="/SchedulingLinkImage.png"
              ></img>
            </div>
          </div>
          <div className={styles.tile_row_stats}>
            <div className={styles.tile_stat}>
              <div className={styles.tile_top_absolute}>
                <div className={styles.stat_title}>Total Outreach</div>
                <div className={styles.stat_value}>953</div>
              </div>
              <FilledAreaChart />
            </div>
            <div className={styles.tile_stat}>
              <div className={styles.tile_top_absolute}>
                <div className={styles.stat_title}>Total Responses</div>
                <div className={styles.stat_value}>535</div>
              </div>
              <FilledAreaChart />
            </div>
            <div className={styles.tile_stat}>
              <div className={styles.tile_top_absolute}>
                <div className={styles.stat_title}>Meetings Scheduled</div>
                <div className={styles.stat_value}>104</div>
              </div>
              <FilledAreaChart />
            </div>
            {/* <div className={styles.tile_stat}></div> */}
          </div>
          <div className={styles.long_tall_tile}>
            <div className={styles.tile_top}>
              <div className={styles.tile_title}>Quickstart Campaigns</div>
            </div>
            <div className={styles.tile_scroll_content}>
              {quickstart_campaigns_data.map((meeting) => (
                <div className={styles.quickstart_campaign_tile}>
                  <div className={styles.tile_info}>
                    <p className={styles.task_title}>{meeting.title}</p>
                    <p className={styles.task_subtitle}>
                      {meeting.contacts_in_campaign} Contacts
                    </p>
                  </div>
                  <div className={styles.campaign_right}>
                    <div className={styles.campaign_start_button}>
                      {/* <PlayIcon
                        width={8}
                        height={8}
                        color="rgba(0, 0, 0, 0.8)"
                      /> */}
                      Start Campaign
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.two_row_layout}>
            <div className={styles.box_layout}>
              <div className={styles.tile_top_absolute_charts}>
                <div className={styles.tile_chart_title}>Schedule Rate</div>
                <div className={styles.tile_chart_value_title}>33%</div>
              </div>
              <ProgressCircle />
            </div>
            <div className={styles.box_layout}>
              <div className={styles.tile_top_absolute_charts}>
                <div className={styles.tile_chart_title}>Total Reach</div>
                <div className={styles.tile_chart_value_title}>9535</div>
              </div>
              <CountryChart width={275} height={275} fullSize={false} />
            </div>
            {/* <div className={styles.tile_stat}></div> */}
          </div>
        </div>
        <div className={styles.top_right_layouts}>
          <div className={styles.tall_tile_bg_color}>
            <div className={styles.tile_top}>
              <div className={styles.tile_title}>
                Getting Started With Artemis
              </div>
            </div>
            <div className={styles.tile_scroll_content}>
              {getting_started_data.map((task) => (
                <div className={styles.getting_started_task}>
                  <div className={styles.tile_left}>
                    <div className={styles.checkmark_icon_border}>
                      <CheckmarkIcon
                        color="rgba(0, 0, 0, 0.5)"
                        width={12}
                        height={12}
                      />
                    </div>
                  </div>
                  <div className={styles.tile_info}>
                    <p className={styles.task_title}>{task.title}</p>
                    <p className={styles.task_subtitle}>{task.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.tall_tile}>
            <div className={styles.tile_top}>
              <div className={styles.tile_title}>Upcoming Meetings</div>
            </div>
            <div className={styles.tile_scroll_content}>
              {meetings_data.map((meeting) => (
                <div className={styles.getting_started_task}>
                  <div className={styles.tile_left}></div>
                  <div className={styles.tile_info}>
                    <p className={styles.task_title}>{meeting.title}</p>
                    <p className={styles.task_subtitle}>{meeting.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
