import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import CreatePlay from "../../components/PortalComponents/PlaybookComponents/PlayBook";

const CreatePlayPage = () => {
  return (
    <>
      <Sidebar childComponent={<CreatePlay />} activeText="Playbook" />
    </>
  );
};

export default CreatePlayPage;
