import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./lists.module.css";
import AddIcon from "../../../assets/SVG/AddIcon";

const lists = [
  { title: "Saved", count: 424 },
  { title: "Shortlist", count: 63 },
  { title: "Closed", count: 144 },
  { title: "Neutrals", count: 53 },
];

export default function SavedLists() {
  const [selectedList, setSelectedList] = useState("Saved");

  return (
    <div className={styles.saved_lists_layout}>
      <input className={styles.saved_lists_search} placeholder="Search Lists" />
      <div className={styles.saved_lists}>
        {lists.map((list, index) => (
          <div
            key={index}
            className={
              selectedList === list.title
                ? styles.selected_list_item
                : styles.list_item
            }
            onClick={() => setSelectedList(list.title)}
          >
            <p className={styles.list_tile}>{list.title}</p>
            <p className={styles.list_count}>{list.count}</p>
          </div>
        ))}
      </div>
      <div className={styles.line_divider}></div>
      <div className={styles.new_list_button}>
        <div className={styles.new_list_icon}>
          <AddIcon color="rgba(0, 0, 0, 1)" width={14} height={14} />
        </div>
        <p className={styles.new_list_text}>New List</p>
      </div>
    </div>
  );
}
