import React from "react";

const CampaignActionIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_3318406"
    enable-background="new 0 0 508.007 508.007"
    height={height}
    width={width}
    viewBox="0 0 508.007 508.007"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill={color}
        d="m505.049 36.242c-2.228-1.797-5.254-2.252-7.912-1.197l-101.194 40.217c-128.126 50.916-260.614 103.567-390.934 155.307-3.005 1.194-4.987 4.091-5.009 7.324-.021 3.235 1.922 6.158 4.91 7.391 38.276 15.787 76.561 31.551 114.846 47.316l16.987 6.995 71.701 169.087c1.255 2.958 4.149 4.839 7.304 4.839.302 0 .605-.018.91-.053 2.281-.262 4.259-1.494 5.535-3.275.066-.083.141-.158.205-.244l76.899-103.412c45.714 18.829 91.426 37.662 137.149 56.471.971.399 1.996.597 3.02.597 1.341 0 2.678-.34 3.878-1.012 2.115-1.184 3.574-3.269 3.959-5.664l8.227-51.077c17.01-105.613 34.598-214.821 52.368-322.134.47-2.822-.618-5.679-2.849-7.476zm-475.952 201.844c124.432-49.408 250.549-99.526 372.707-148.072l68.553-27.243c-47.625 31.399-109.745 73.727-170.077 114.835-59.7 40.679-116.174 79.159-158.358 106.952l-16.121-6.638c-32.236-13.273-64.472-26.547-96.704-39.834zm280.123-47.36c46.38-31.603 93.818-63.926 134.872-91.421l-211.113 228.335c-1.038 1.123-1.729 2.522-1.992 4.029l-18.439 106.023-59.878-141.204c42.061-27.751 97.745-65.694 156.55-105.762zm-79.93 243.352 15.64-89.927c13.073 5.386 26.149 10.764 39.223 16.15zm210.569-70.749-6.636 41.186c-60.29-24.805-120.57-49.635-180.85-74.468l233.94-253.023c-15.757 95.713-31.335 192.431-46.454 286.305z"
      ></path>
    </g>
  </svg>
);

export default CampaignActionIcon;
