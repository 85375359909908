import React from "react";
import { useContext, useEffect } from "react";
import styles from "./play.module.css";
import { useState } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import CancelIcon from "../../../../../assets/SVG/CancelIcon";

import message_sequence_data from "../../../../../data/message_sequence_data";
import GmailIcon from "../../../../../assets/SVG/PlaybookIcons.js/GmailIcon";

function Play({ selectedPlay }) {
  return (
    <div className={styles.play_layout}>
      {selectedPlay.message_sequence.map((message, index) => (
        <>
          <PlayTile message={message} index={index} />
        </>
      ))}
    </div>
  );
}

export default Play;

function PlayTile({ message, index }) {
  const step = index + 1;
  return (
    <div className={styles.play_tile_layout}>
      <div className={styles.play_tile_tracker}>
        <div className={styles.play_tile_step_count}>Action {step}</div>
        <VerticalLine />
      </div>
      {message.type === "Email" ? (
        <div className={styles.play_tile_content}>
          <EmailLayout message={message} />
        </div>
      ) : (
        <div className={styles.play_tile_content}>
          <MessageLayout message={message} />
        </div>
      )}
    </div>
  );
}

function VerticalLine() {
  return <div className={styles.vertical_line}></div>;
}

function EmailLayout({ index = 1, onDelete, message }) {
  const handleDeleteClick = () => {
    // onDelete(index);
    return;
  };

  function updateMessage() {
    return;
  }

  const handleFieldChange = (field, value) => {
    updateMessage(index, field, value);
  };

  const { profile } = useContext(AuthContext);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  //   const activeEmail = profile.connected_accounts[0];
  const activeEmail = "justinrcose@gmail.com";

  useEffect(() => {
    if (activeEmail) {
      handleFieldChange("from", activeEmail);
    }
  }, [activeEmail]);

  const handleDropdownClick = (account) => {
    handleFieldChange("from", account);
    setIsDropdownOpen(false);
  };

  return (
    <div className={styles.modal_content}>
      {true && (
        <div
          className={styles.channel_icon}
          style={{ backgroundColor: "rgba(15, 157, 88, 0.2)" }}
        >
          <GmailIcon width={15} height={15} />
        </div>
      )}
      <p className={styles.header_title}>Email</p>

      <div className={styles.email_info}>
        <p className={styles.email_info_title}>From:</p>
        <input
          value={activeEmail}
          onChange={(e) => handleFieldChange("from", e.target.value)}
          className={styles.email_info_content}
          placeholder="Add a subject"
        />
        {/* <div
          className={styles.email_from}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
       
          {activeEmail}
          <img
            src="/images/general_icons/DownArrowIcon.png"
            alt="down"
            className={`${styles.down_icon} ${
              isDropdownOpen ? styles.rotate_180 : ""
            }`}
          ></img>
          {isDropdownOpen && (
            <div className={styles.dropdown}>
              {profile.connected_accounts.map((account, index) => (
                <div
                  key={index}
                  className={styles.dropdown_item}
                  onClick={() => handleDropdownClick(account)}
                >
                  {account.name}
                </div>
              ))}
            </div>
          )}
        </div> */}
      </div>
      <div className={styles.section_line_divider}></div>
      <div className={styles.email_info}>
        <p className={styles.email_info_title}>Subject:</p>
        <input
          value={message.subject}
          onChange={(e) => handleFieldChange("subject", e.target.value)}
          className={styles.email_info_content}
          placeholder="Add a subject"
        />
      </div>
      <div className={styles.section_line_divider}></div>
      <div className={styles.email_body}>
        <textarea
          value={message.body}
          onChange={(e) => handleFieldChange("body", e.target.value)}
          className={styles.email_body_area}
          placeholder="Write your message here..."
        ></textarea>
      </div>
    </div>
  );
}

function MessageLayout({ index = 1, onDelete, message }) {
  const handleDeleteClick = () => {
    // onDelete(index);
    return;
  };

  function updateMessage() {
    return;
  }

  const handleFieldChange = (field, value) => {
    updateMessage(index, field, value);
  };

  return (
    <div className={styles.modal_content}>
      {true && (
        <div
          className={styles.channel_icon}
          style={{ backgroundColor: "rgba(15, 157, 88, 0.2)" }}
        >
          <GmailIcon width={15} height={15} />
        </div>
      )}
      <p className={styles.header_title}>{message.type} Message</p>

      <div className={styles.section_line_divider}></div>
      <div className={styles.message_body}>
        <textarea
          value={message.body}
          onChange={(e) => handleFieldChange("body", e.target.value)}
          className={styles.message_body_area}
          placeholder="Write your message here..."
        ></textarea>
      </div>
    </div>
  );
}
