import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import styles from "./admin_frames.module.css";
import { useState, useContext } from "react";
import SearchbarHeader from "../../PortalComponents/UniversalComponents/SearchbarHeader/SearchbarHeader";
import AdminManageFrame from "./AdminManageFrame";
import { toast } from "react-toastify";
import { CreateFramePopup } from "../../PopUpComponents/CreateFramePopup/CreateFramePopup";
import CircularLoader from "../../PortalComponents/UniversalComponents/CircularLoader/CircularLoader";
import AddIcon from "../../../assets/SVG/AddIcon";
import Frame from "./Frame";

import { AdminContext } from "../../../context/AdminContext";

import { useNavigate } from "react-router";

export default function AdminFrames() {
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [selectedTrainingData, setSelectedTrainingData] = useState(null);
  // const { guidelines } = useContext(ChatbotGuidelinesContext);
  const [createPopup, setCreatePopup] = useState(false);

  const [loadingTrainingDataFile, setLoadingTrainingDataFile] = useState(false);
  const [loadingPopup, setLoadingPopup] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const { frames, createNewFrame } = useContext(AdminContext);
  const [filteredIntents, setFilteredIntents] = useState(frames);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const filtered = frames.filter((intent) =>
      intent.action.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredIntents(filtered);
  }, [searchTerm, frames]);

  function handleModalNav(selectedTrainingData) {
    setSelectedTrainingData(selectedTrainingData);
    console.log(selectedTrainingData);
    setIsEditing(!isEditing);
  }

  function back() {
    setIsEditing(!isEditing);
    setSelectedTrainingData(null);
    navigate(`/admin/frames`);
  }

  const handleCreateNew = async (event) => {
    event.stopPropagation();
    setCreatePopup(true);
  };

  const createFrame = async (frameName) => {
    try {
      setLoadingPopup(true);
      const newFrameId = await createNewFrame(frameName);
      setCreatePopup(false);

      const newFrame = frames.find((frame) => frame.id === newFrameId);
      if (newFrame) {
        setSelectedTrainingData(newFrame);
        setIsEditing(true);
      }
      setLoadingPopup(false);
      toast.success("New frame created successfully!");
    } catch (error) {
      toast.error("Error creating new frame");
      console.error(error);
    }
  };

  //
  return (
    <div className={styles.main_layout}>
      {loadingTrainingDataFile ? (
        <div className={styles.loading_page}>
          <CircularLoader size="50px" color="rgba(0, 61, 130, 1)" />
        </div>
      ) : (
        <>
          {!isEditing ? (
            <p className={styles.page_title_margin}>Frames</p>
          ) : (
            <div className={styles.header_section}></div>
          )}
          <div className={styles.edit_guidelinees_modal}>
            {!isEditing ? (
              <>
                <div className={styles.guideline_controls}>
                  <SearchbarHeader
                    type="EditChatbot"
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                  <div
                    className={styles.guideline_add}
                    onClick={handleCreateNew}
                  >
                    <AddIcon color="rgba(0, 0, 0, 1)" width={20} height={20} />
                  </div>
                </div>
                {/* <div className={styles.section_divider}></div> */}

                <div className={styles.all_intents_container}>
                  {filteredIntents.map((data, index) => {
                    return (
                      <Frame
                        data={data}
                        index={index}
                        handleModalNav={handleModalNav}
                      />
                    );
                  })}
                  <div className={styles.spacer}></div>
                </div>
              </>
            ) : (
              <AdminManageFrame
                selectedTrainingData={selectedTrainingData}
                isCreating={isCreating}
                back={back}
              />
            )}
          </div>

          <CreateFramePopup
            openModal={createPopup}
            closeModal={() => setCreatePopup(false)}
            loading={loadingPopup}
            actionFunction={createFrame}
            desc={"Create New Training Data"}
          />
        </>
      )}
    </div>
  );
}
