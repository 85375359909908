import React from "react";

const IntegrationsIcon = ({
  width = 15,
  height = 15,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    version="1.1"
    id="fi_786386"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          fill={color}
          d="M448.801,271H497c8.284,0,15-6.716,15-15V111.4c0-8.284-6.716-15-15-15H367.4V63.2c0-34.849-28.352-63.2-63.2-63.2
			S241,28.352,241,63.2v33.2H111.4c-8.284,0-15,6.716-15,15V241H63.2C28.352,241,0,269.352,0,304.2s28.352,63.2,63.2,63.2h33.2V497
			c0,8.284,6.716,15,15,15H256c8.284,0,15-6.716,15-15v-48.2c0-18.307,14.894-33.2,33.2-33.2c18.306,0,33.2,14.894,33.2,33.2V497
			c0,8.284,6.716,15,15,15H497c8.284,0,15-6.716,15-15V352.4c0-8.284-6.716-15-15-15h-48.199c-18.308,0-33.201-14.894-33.201-33.2
			C415.6,285.894,430.493,271,448.801,271z"
        ></path>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default IntegrationsIcon;
