import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import styles from "./admin_frames.module.css";
import { useState, useContext } from "react";
import { AdminContext } from "../../../context/AdminContext";
import CircularLoader from "../../PortalComponents/UniversalComponents/CircularLoader/CircularLoader";

export default function Frame({ data, index, handleModalNav }) {
  const { syncFrameToPinecone } = useContext(AdminContext);
  const [isSyncing, setIsSyncing] = useState(false);

  async function syncToPinecone() {
    setIsSyncing(true);
    try {
      const response = await syncFrameToPinecone(data.id);

      if (response?.success) {
        console.log(
          "Frame successfully synced with Pinecone:",
          response.message
        );
      } else {
        console.warn(
          "Failed to sync frame to Pinecone:",
          response?.message || "Unknown error."
        );
      }
    } catch (error) {
      console.error("An error occurred while syncing to Pinecone:", error);
    } finally {
      setIsSyncing(false);
    }
  }

  return (
    <div className={styles.data_set} key={index}>
      <div className={styles.data_set_top}>
        <div className={styles.training_data_text}>
          <p className={styles.training_data_title}>{data.action}</p>
          <p className={styles.training_data_subtitle}>
            {data.phrases.length} Data Points • {data.entities.length} Entities
          </p>
        </div>
        <p className={styles.data_set_prompt}>Prompt: {data.prompt}</p>
        <p className={styles.training_data_last_updated}>
          Last Updated: {formatTimestamp(data.last_updated)}
        </p>
      </div>
      <div className={styles.data_set_middle}>
        <p className={styles.training_data_title}>Entities</p>
        {data.entities.map((entity) => {
          return <p className={styles.training_data_subtitle}>{entity}</p>;
        })}
      </div>
      <div className={styles.data_set_bottom}>
        {true ? (
          <div className={styles.top_right}>
            <p className={styles.ready_text}>Synced to Pinecone</p>
            <CheckMarkIcon />
          </div>
        ) : (
          <div className={styles.top_right}>
            <p className={styles.not_ready_text}>Validation Failed</p>
            <CrossIcon />
          </div>
        )}
        <div className={styles.frame_controls}>
          <div className={styles.open_data_button} onClick={syncToPinecone}>
            {isSyncing ? "Syncing..." : " Sync to Pinecone"}
          </div>
          <div
            className={styles.open_data_button}
            onClick={() => handleModalNav(data)}
          >
            Open Data
          </div>
        </div>
      </div>
    </div>
  );
}

function formatTimestamp(timestamp) {
  try {
    const date = new Date(parseInt(timestamp, 10));

    const options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    return date.toLocaleString(undefined, options);
  } catch (error) {
    console.error("Error in formatting timestamp:", error);
    return "";
  }
}

function CheckMarkIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 6L9 17L4 12"
        // stroke="currentColor"
        className={styles.check_svg}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function CrossIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18M6 6l12 12"
        className={styles.red_cross_svg}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
