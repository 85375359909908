import React from "react";
import styles from "./integrations.module.css";
import SearchbarHeader from "../UniversalComponents/SearchbarHeader/SearchbarHeader";
import SearchIcon from "../../../assets/SVG/SearchIcon";

function Integrations() {
  const integrations = [
    {
      id: "1",
      name: "Calendly",
      type: "CRM",
      summary:
        "Sync Calendly with Artemis to automate meeting scheduling and reminders, keeping your calendar organized.",
      logo_image: "/images/IntegrationLogos/calendly_logo.png",
      color: "rgba(0, 107, 255, 0.2)",
      connected: true,
    },
    {
      id: "2",
      name: "Hubspot",
      type: "CRM",
      summary:
        "Connect HubSpot to Artemis for automatic contact updates and streamlined CRM management.",
      logo_image: "/images/IntegrationLogos/hubspot_logo.png",
      color: "rgba(245, 119, 34, 0.2)",
      connected: false,
    },
  ];

  function redirectToCalendlyAuth() {
    const clientId = "WMCng7JZhNR4aMtRlZ7BP-QO284lHxzw756gki9Kbrw";
    const redirectUri = "https://my.site.com/auth/calendly";
    const url = `https://auth.calendly.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`;

    window.location.href = url;
  }

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Integrations</p>
      {/* <SearchbarHeader /> */}
      <div className={styles.search}>
        <SearchIcon width={20} height={20} />
        <input
          className={styles.search_container_input}
          placeholder="Search all integrations..."
        ></input>
      </div>

      <div className={styles.integration_tiles}>
        {integrations.map((integration, index) => {
          return (
            <div className={styles.tile}>
              <div
                className={styles.tile_header}
                style={{ backgroundColor: integration.color }}
              >
                <img
                  className={styles.logo_image}
                  src={integration.logo_image}
                  alt="logo"
                ></img>
              </div>
              <div className={styles.content}>
                <div className={styles.text_content}>
                  <p className={styles.title}>{integration.name}</p>
                  <p className={styles.type}>{integration.type}</p>
                  <p className={styles.summary}>{integration.summary}</p>
                </div>
                <div
                  className={styles.connect_button}
                  onClick={redirectToCalendlyAuth}
                >
                  Connect
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Integrations;
