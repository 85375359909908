import React from "react";
import { useState, useContext } from "react";

import styles from "./auth.module.css";

import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router";

import CircularLoader from "../PortalComponents/UniversalComponents/CircularLoader/CircularLoader";

const SignInComponent = () => {
  const navigate = useNavigate();

  const { signIn } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState("");

  const [error, setError] = useState(null);

  function handleSignUp() {
    navigate("/sign-up");
  }

  function handleForgotPassword() {
    navigate("/forgot-password");
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await signIn(email, password);
      navigate("/dashboard");
    } catch (error) {
      console.error("Sign-in error:", error);
      setError("Invalid Login Credentials");
    } finally {
      setLoading(false);
    }
  };

  const canContinue = email !== "" && password !== "";

  return (
    <div className={styles.home_components}>
      <div className={styles.sidebar}>
        <img
          alt="logo"
          src="/ArtemisLogo.png"
          className={styles.logo_icon}
        ></img>
        <p className={styles.page_title}>Welcome Back 👋</p>
        <p className={styles.page_subtitle}>
          Sign in to access your Artemis account
        </p>
        <p className={styles.login_input_titles}>Email Address</p>
        <div className={styles.email_input}>
          <img
            className={styles.auth_input_icon}
            alt="email"
            src="/EmailIcon.png"
          ></img>
          <input
            onChange={(event) => setEmail(event.target.value)}
            className={styles.input}
          ></input>
        </div>
        <p className={styles.login_input_titles}>Password</p>
        <div className={styles.email_input}>
          <img
            className={styles.auth_input_icon}
            alt="password"
            src="/PasswordIcon.png"
          ></img>
          <input
            type="password"
            onChange={(event) => setPassword(event.target.value)}
            className={styles.input}
          ></input>
        </div>

        <p className={styles.forgot_password} onClick={handleForgotPassword}>
          Forgot Password?
        </p>

        {error && <p className={styles.error_text}>{error}</p>}
        {canContinue ? (
          <div onClick={handleSubmit} className={styles.page_button}>
            {loading ? (
              <CircularLoader size="30%" color="rgba(255, 255, 255, 1)" />
            ) : (
              <p className={styles.join_button_text}>Sign In</p>
            )}
          </div>
        ) : (
          <div className={styles.page_button_inactive}>
            <p className={styles.join_button_text}>Sign In</p>
          </div>
        )}
        <div className={styles.page_break_line}>
          <div className={styles.line_half}></div>
          <p className={styles.or_text}>Or</p>
          <div className={styles.line_half}></div>
        </div>

        <div className={styles.auth_options_3rd}>
          <div className={styles.sign_in_with_google}>
            <img
              className={styles.google_icon}
              alt="password"
              src="/AuthProviders/google.png"
            ></img>
            <p className={styles.login_with_auth_text}>Sign in with Google</p>
          </div>
          <div className={styles.sign_in_with_google}>
            <img
              className={styles.google_icon}
              alt="password"
              src="/AuthProviders/microsoft.png"
            ></img>
            <p className={styles.login_with_auth_text}>
              Sign in with Microsoft
            </p>
          </div>
        </div>

        <div className={styles.prompt_texts}>
          <p className={styles.note}>
            Don't have an account?{" "}
            <span className={styles.clickable_text} onClick={handleSignUp}>
              Sign Up
            </span>
          </p>
        </div>
      </div>
      <div className={styles.right_side_sign_in}>
        <img className={styles.blue_background} src="/bluesquares.jpeg"></img>
        {/* <img className={styles.preview_ss} src="/SignInSS.png"></img> */}
      </div>
    </div>
  );
};

export default SignInComponent;
