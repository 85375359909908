const FunnelIcon = ({
  width = 14,
  height = 14,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_4126552"
    enable-background="new 0 0 512 512"
    height={height}
    width={width}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill={color}
        d="m464.867 224.934h-417.733c-13.262-.044-20.012 16.265-10.606 25.606l172.34 172.34v74.12c0 5.198 2.692 10.026 7.114 12.76 4.504 2.785 10.032 2.937 14.594.656l64.266-32.134c5.082-2.54 8.292-7.734 8.292-13.416v-41.986l172.34-172.34c9.409-9.346 2.649-25.652-10.607-25.606z"
      ></path>
      <path
        fill={color}
        d="m303.134 47.133c0-25.989-21.145-47.133-47.134-47.133-62.529 2.589-62.51 91.687 0 94.267 25.989 0 47.134-21.144 47.134-47.134z"
      ></path>
      <path
        fill={color}
        d="m207.8 190.667h96.4c8.284 0 15-6.716 15-15v-18.2c-3.481-83.864-122.95-83.799-126.4 0v18.2c0 8.284 6.716 15 15 15z"
      ></path>
      <path
        fill={color}
        d="m463.8 47.133c0-25.989-21.144-47.133-47.133-47.133-62.529 2.589-62.51 91.687 0 94.267 25.989 0 47.133-21.144 47.133-47.134z"
      ></path>
      <path
        fill={color}
        d="m416.667 94.267c-34.849 0-63.2 28.352-63.2 63.2v18.2c0 8.284 6.716 15 15 15h96.4c8.284 0 15-6.716 15-15v-18.2c-.001-34.849-28.352-63.2-63.2-63.2z"
      ></path>
      <path
        fill={color}
        d="m142.467 47.133c0-25.989-21.144-47.133-47.133-47.133-62.529 2.589-62.511 91.687 0 94.267 25.989 0 47.133-21.144 47.133-47.134z"
      ></path>
      <path
        fill={color}
        d="m47.134 190.667h96.4c8.284 0 15-6.716 15-15v-18.2c-3.481-83.864-122.95-83.799-126.4 0v18.2c0 8.284 6.715 15 15 15z"
      ></path>
    </g>
  </svg>
);

export default FunnelIcon;
