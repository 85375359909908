import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./global_database.module.css";
import AddIcon from "../../../assets/SVG/AddIcon";
import SearchIcon from "../../../assets/SVG/SearchIcon";

export default function GlobalDatabaseFilters() {
  const [activeTab, setActiveTab] = useState("People");

  // Function to select the correct filter array based on the active tab
  const getActiveFilters = () => {
    if (activeTab === "People") return peopleFilters;
    if (activeTab === "Companies") return companiesFilters;
    if (activeTab === "Linkedin") return linkedinFilters;
  };

  return (
    <div className={styles.global_database_filters}>
      <div className={styles.global_database_filters_header}>
        <div className={styles.header_titles}>
          <p className={styles.page_title}>Global Database</p>
          {/* <div className={styles.artemis_search}>Ask Artemis</div> */}
        </div>

        <div className={styles.search_layout}>
          <input
            className={styles.search_box}
            placeholder="e.g. CMO's of B2B companies in California"
          />
          <div className={styles.search_button}>
            <SearchIcon color="rgba(255, 255, 255, 1)" width={20} height={20} />
          </div>
        </div>
        <div className={styles.filter_controls_layout}>
          {/* <p className={styles.filter_title}>Filters</p> */}
          <div className={styles.filter_controls}>
            <p className={styles.filter_control}>Clear filters</p>
            <p className={styles.filter_control}>Save filters</p>
          </div>
        </div>
      </div>
      <div className={styles.database_options}>
        <div className={styles.tab_switcher}>
          <div
            className={`${styles.tab} ${
              activeTab === "People" ? styles.active_tab : ""
            }`}
            onClick={() => setActiveTab("People")}
          >
            People
          </div>
          <div
            className={`${styles.tab} ${
              activeTab === "Companies" ? styles.active_tab : ""
            }`}
            onClick={() => setActiveTab("Companies")}
          >
            Companies
          </div>
          <div
            className={`${styles.tab} ${
              activeTab === "Linkedin" ? styles.active_tab : ""
            }`}
            onClick={() => setActiveTab("Linkedin")}
          >
            LinkedIn Profiles
          </div>
        </div>
      </div>
      <div className={styles.filters}>
        {getActiveFilters().map((filter) => (
          <div className={styles.database_filter}>
            <p className={styles.database_filter_title}>{filter}</p>
            <AddIcon color="rgba(0, 0, 0, 1)" />
          </div>
        ))}
      </div>
    </div>
  );
}

const peopleFilters = [
  "Saved Contacts",
  "Profile Keywords",
  "Contact Boolean Search",
  "Contact Name",
  "Job Title",
  "Past Job Title",
  "Past Company",
  "Seniority",
  "Industry",
  "Company Size",
  "Country",
  "Location",
  "School",
  "Degree",
  "Skills",
  "Years of Experience",
  "Department",
  "Company Type",
  "Revenue",
  "Language Spoken",
];

const companiesFilters = [
  "Company Name",
  "Industry",
  "Company Size",
  "Revenue",
  "Founded Year",
  "Headquarters Location",
  "Number of Employees",
  "Company Type",
  "Country",
  "Region",
  "Sub-Industry",
  "Funding Rounds",
  "Parent Company",
  "Subsidiaries",
  "Acquisitions",
  "Website Traffic",
  "Tech Stack",
  "Social Media Presence",
  "Revenue Growth",
  "Employee Growth",
];

const linkedinFilters = [
  "Profile Keywords",
  "Job Title",
  "Current Company",
  "Past Company",
  "Industry",
  "Seniority",
  "Years of Experience",
  "Skills",
  "School",
  "Degree",
  "Language Spoken",
  "Location",
  "Country",
  "Connections",
  "Recommendations",
  "Open to Work",
  "Active Status",
  "Profile Views",
  "Publications",
  "Volunteer Experience",
];
