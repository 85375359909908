import React, { useState, useContext, useEffect } from "react";
import styles from "./play_builder.module.css";
import { AuthContext } from "../../../../context/AuthContext";
import CancelIcon from "../../../../assets/SVG/CancelIcon";

import Play from "./Play/Play";

export default function PlayBuilderSidebar({}) {
  return <div className={styles.play_builder_sidebar}></div>;
}
