/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */

import React from "react";
import styles from "./popup.module.css";
import { useState } from "react";
import { useRef } from "react";
import CircularLoader from "../../PortalComponents/UniversalComponents/CircularLoader/CircularLoader";
import AddIcon from "../../../assets/SVG/AddIcon";

export function CreateFramePopup(props) {
  const { openModal, closeModal, actionFunction, loading, desc } = props;
  const [name, setName] = useState("");
  const [file, setFile] = useState("");

  const canCreate = name !== "";

  const fileInputRef = useRef();

  const handleBrowseFilesClick = () => {
    fileInputRef.current.click();
  };

  if (!openModal) {
    return null;
  }

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const modalStyle = getModalStyle();

  function handleFileUpload(event) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  }

  function deleteFile() {
    setFile(null);
  }

  return (
    <div open={openModal} onClose={closeModal} className={styles.overlay}>
      <div style={modalStyle} className={styles.modalPaper}>
        <div className={styles.modal_header}></div>
        <div className={styles.modal_body}>
          <div className={styles.modal_icon}>
            <AddIcon color="rgba(0, 0, 0, 1)" />
          </div>
          <p className={styles.popup_text}>{desc}</p>

          <div className={styles.input_name_box}>
            <input
              className={styles.input_name}
              placeholder="Frame Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className={styles.popup_buttons}>
            <div
              className={styles.cancel_button}
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </div>
            {canCreate ? (
              <div
                className={styles.create_button}
                onClick={() => actionFunction(name, file)}
              >
                {loading ? (
                  <CircularLoader size="24px" color="rgba(255, 255, 255, 1)" />
                ) : (
                  "Create"
                )}
              </div>
            ) : (
              <div className={styles.create_button_disabled}>Create</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
//
function CrossIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18M6 6l12 12"
        className={styles.red_cross_svg}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
