export const task_data = [
  {
    id: 1,
    title: "Call to touch base",
    priority: "High",
    dueDate: "October 13th",
    contact_name: "Alex Smith",
    contact_image: "ArtemisDummyImages/contact12.jpg",
    contact_position: "SRE",
    contact_company: "Techforce",
    createdAt: "2024-10-12",
    type: "Phone Call",
  },
  {
    id: 1,
    title: "Call to touch base",
    priority: "High",
    dueDate: "October 13th",
    contact_name: "Alex Teero",
    contact_image: "ArtemisDummyImages/contact11.webp",
    contact_position: "Sales Director",
    contact_company: "Spectre Inc.",
    createdAt: "2024-10-12",
    type: "Phone Call",
  },
  {
    id: 1,
    title: "Call to touch base",
    priority: "Medium",
    dueDate: "October 13th",
    contact_name: "Taylor Elise",
    contact_image: "ArtemisDummyImages/contact10.jpg",
    contact_position: "Sales Director",
    contact_company: "Spectre Inc.",
    createdAt: "2024-10-12",
    type: "Phone Call",
  },
  {
    id: 1,
    title: "Call to touch base",
    priority: "Low",
    dueDate: "October 13th",
    contact_name: "Alex Smith",
    contact_image: "ArtemisDummyImages/contact9.jpg",
    contact_position: "Engineer",
    contact_company: "Space X",
    createdAt: "2024-10-12",
    type: "Phone Call",
  },
  {
    id: 1,
    title: "Call to touch base",
    priority: "Low",
    dueDate: "October 13th",
    contact_name: "John Keenes",
    contact_image: "ArtemisDummyImages/contact8.jpg",
    contact_position: "Product Manager",
    contact_company: "Epic",
    createdAt: "2024-10-12",
    type: "Phone Call",
  },
  {
    id: 1,
    title: "Call to touch base",
    priority: "Low",
    dueDate: "October 13th",
    contact_name: "Macey Taylor",
    contact_image: "ArtemisDummyImages/contact7.jpg",
    contact_position: "Frontend Engineer",
    contact_company: "Apollo",
    createdAt: "2024-10-12",
    type: "Phone Call",
  },
];
