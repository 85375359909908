import React from "react";

const GmailIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_5968534"
    enable-background="new 0 0 512 512"
    width={width}
    height={height}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m34.909 448.047h81.455v-197.818l-53.338-93.138-63.026 5.865v250.182c0 19.287 15.622 34.909 34.909 34.909z"
        fill="#0085f7"
      ></path>
      <path
        d="m395.636 448.047h81.455c19.287 0 34.909-15.622 34.909-34.909v-250.182l-62.935-5.865-53.428 93.138v197.818z"
        fill="#00a94b"
      ></path>
      <path
        d="m395.636 98.956-47.847 91.303 47.847 59.97 116.364-87.273v-46.545c0-43.142-49.251-67.782-83.782-41.891z"
        fill="#ffbc00"
      ></path>
      <path
        clip-rule="evenodd"
        d="m116.364 250.229-45.593-96.31 45.593-54.963 139.636 104.727 139.636-104.727v151.273l-139.636 104.727z"
        fill="#ff4131"
        fill-rule="evenodd"
      ></path>
      <path
        d="m0 116.411v46.545l116.364 87.273v-151.273l-32.582-24.436c-34.531-25.891-83.782-1.251-83.782 41.891z"
        fill="#e51c19"
      ></path>
    </g>
  </svg>
);

export default GmailIcon;
