import React from "react";

const TalkToAiIcon = ({
  width = 17,
  height = 17,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_7747507"
    enable-background="new 0 0 511.008 511.008"
    width={width}
    height={height}
    viewBox="0 0 511.008 511.008"
  >
    <g>
      <path
        fill={color}
        d="m248.28 319.275c0-9.447-7.686-17.133-17.133-17.133-22.698.861-22.692 33.409 0 34.267 9.447-.001 17.133-7.687 17.133-17.134z"
      ></path>
      <path
        fill={color}
        d="m500.214 278.141-45.257-60.342c-9.69-246.469-344.044-300.928-433.167-74.191h90.677l27.74-27.74c2.813-2.812 6.628-4.393 10.606-4.393h35.661c17.604-49.883 90.754-38.543 91.805 15.001-1.056 53.552-74.21 64.875-91.805 14.999h-29.448l-27.741 27.74c-2.813 2.813-6.628 4.393-10.606 4.393h-105.925c-2.51 11.134-4.183 22.579-4.96 34.267h275.081c17.604-49.883 90.754-38.543 91.805 15.001-1.056 53.552-74.21 64.875-91.805 14.999h-275.078c.773 11.614 2.437 23.064 4.956 34.267h105.927c3.978 0 7.793 1.58 10.606 4.393l27.741 27.74h29.448c17.604-49.883 90.754-38.543 91.805 15.001-1.056 53.552-74.21 64.875-91.805 14.999h-35.661c-3.978 0-7.793-1.581-10.606-4.393l-27.74-27.74h-90.72c12.536 33.12 32.903 63.092 59.907 87.322l-25.727 102.906c-1.12 4.481-.113 9.229 2.729 12.869 2.843 3.641 7.204 5.769 11.823 5.769h208.867c6.883 0 12.883-4.685 14.552-11.362l12.785-51.143 99.075 14.153c7.605 1.087 14.809-3.754 16.673-11.211l30.727-122.907 43.375-28.917c3.431-2.287 5.768-5.885 6.464-9.948.696-4.062-.311-8.233-2.784-11.532z"
      ></path>
      <path
        fill={color}
        d="m248.28 126.475c0-9.447-7.686-17.133-17.133-17.133-22.698.861-22.692 33.409 0 34.267 9.447-.001 17.133-7.687 17.133-17.134z"
      ></path>
      <path
        fill={color}
        d="m344.68 222.875c0-9.447-7.686-17.133-17.133-17.133-22.698.861-22.692 33.409 0 34.267 9.447-.001 17.133-7.687 17.133-17.134z"
      ></path>
    </g>
  </svg>
);

export default TalkToAiIcon;
