import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import CircularLoader from "./components/PortalComponents/UniversalComponents/CircularLoader/CircularLoader";
import { ToastContainer } from "react-toastify";

function App(props) {
  const { loading, creatingAccount } = useContext(AuthContext);
  const { childComp } = props;
  return (
    <div className="App">
      <ToastContainer />
      {loading || creatingAccount ? (
        <div className="Loader">
          <CircularLoader />
        </div>
      ) : (
        childComp
      )}
    </div>
  );
}

export default App;
