export const meetings_data = [
  {
    id: "1",
    title: "Justin & Thomas",
    subtitle: "3pm - 4:30pm",
    icon: "i",
    complete: false,
  },
  {
    id: "1",
    title: "Demo Call /w Ashley",
    subtitle: "3pm - 4:30pm",
    icon: "i",
    complete: false,
  },
  {
    id: "1",
    title: "30 min Recruitment Call /w James",
    subtitle: "3pm - 4:30pm",
    icon: "i",
    complete: false,
  },
  {
    id: "1",
    title: "Justin & Katilin",
    subtitle: "3pm - 4:30pm",
    icon: "i",
    complete: false,
  },
  {
    id: "1",
    title: "30 min Recruitment Call /w Bruce",
    subtitle: "3pm - 4:30pm",
    icon: "i",
    complete: false,
  },
];
