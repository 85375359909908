import React, { useState } from "react";
import styles from "../meetings.module.css";
import Switch from "../../UniversalComponents/Switch/Switch";

export const scheduling_pages_data = [
  {
    id: "1",
    title: "15 Minute Call",
    length: "15 Minutes",
  },
  {
    id: "1",
    title: "30 Minute Call",
    length: "30 Minutes",
  },
  {
    id: "1",
    title: "45 Minute Call",
    length: "45 Minutes",
  },
  {
    id: "1",
    title: "1 Hour Call",
    length: "60 Minutes",
  },
];

function SchedulingPages() {
  return (
    <div className={styles.meeting_page_layout}>
      <div className={styles.scheduling_link_pages}>
        {scheduling_pages_data.map((link) => (
          <div className={styles.scheduling_link_tile}>
            <div className={styles.scheduling_link_top}>
              <div className={styles.meeting_information}>
                <p className={styles.meeting_title}>{link.title}</p>
                <p className={styles.meeting_time}>{link.length}</p>
              </div>
              <Switch />
            </div>
            {/* <p className={styles.scheduling_link_text}>
              tryartemis.com/meeting/justin-cose
            </p> */}
            <div className={styles.scheduling_link_bottom}>
              <div className={styles.scheduling_link_button}>Copy Link</div>
              <div className={styles.scheduling_link_button}>Preview</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SchedulingPages;
