import GmailIcon from "../assets/SVG/PlaybookIcons.js/GmailIcon";
import LinkedinIcon from "../assets/SVG/PlaybookIcons.js/LinkedinIcon";

const quickstart_campaigns_data = [
  {
    id: "1",
    title: "B2B Founders in California",
    contacts_in_campaign: 1041,
    sequence: [LinkedinIcon, LinkedinIcon, GmailIcon],
  },
  {
    id: "1",
    title: "Remote SRE Recruitment",
    contacts_in_campaign: 2425,
    sequence: [GmailIcon, LinkedinIcon, GmailIcon],
  },
  {
    id: "1",
    title: "Sales Directors in New York",
    contacts_in_campaign: 425,
    sequence: [GmailIcon, LinkedinIcon, LinkedinIcon],
  },
  {
    id: "1",
    title: "Social Media Manager Recruitment",
    contacts_in_campaign: 935,
    sequence: [LinkedinIcon, LinkedinIcon, GmailIcon, GmailIcon],
  },
  {
    id: "1",
    title: "Financial Advisor Recruitment",
    contacts_in_campaign: 2942,
    sequence: [LinkedinIcon, LinkedinIcon],
  },
];

export default quickstart_campaigns_data;
