import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import GlobalDatabaseComponent from "../../components/PortalComponents/GlobalDatabaseComponents/GlobalDatabaseComponent";

const GlobalDatabasePage = () => {
  return (
    <>
      <Sidebar
        childComponent={<GlobalDatabaseComponent />}
        activeText="Global Database"
      />
    </>
  );
};

export default GlobalDatabasePage;
