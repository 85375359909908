import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./lists.module.css";
import ListTile from "./ListTile";
import ListTileLarge from "./ListTileLarge";
import { useNavigate } from "react-router-dom";
import contacts_data from "../../../data/contacts_data";
import RefreshIcon from "../../../assets/SVG/RefreshIcon";
import AddIcon from "../../../assets/SVG/AddIcon";
import SearchIcon from "../../../assets/SVG/SearchIcon";
import { CampaignContext } from "../../../context/CampaignContext";

import ContactListControls from "../UniversalComponents/ContactListControls/ContactListControls";

import SavedLists from "./SavedLists";

export default function ListComponents() {
  const [activeTab, setActiveTab] = useState("All");
  const { fetchUserCampaigns } = useContext(CampaignContext);

  const tileView = false;

  // const tabs = ["Account", "Payment", "Manage Subscription"];
  const tabs = ["All", "Active", "Paused", "Completed"];
  const navigate = useNavigate();

  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    async function loadCampaigns() {
      try {
        const campaignsData = await fetchUserCampaigns();
        setCampaigns(campaignsData);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    }

    loadCampaigns();
  }, [activeTab]);

  function handleCreate() {
    navigate(`/create-campaign`);
  }

  const [hover, setHover] = useState(false);

  return (
    <div className={styles.main_layout}>
      {/* <div className={styles.page_header}>
        <div
          className={styles.create_campaign_button}
          onClick={handleCreate}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <AddIcon
            color={hover ? "rgba(0, 0, 0, 1)" : "rgba(255, 255, 255, 1)"}
          />
          Export Contacts
        </div>
        <div>
          <p className={styles.page_title}>Your Lists</p>
        </div>
      </div> */}
      <div className={styles.list_content_page}>
        <SavedLists />
        <div className={styles.list_content_layout}>
          <div className={styles.page_header}>
            <p className={styles.page_title}>Your Lists</p>

            <div className={styles.create_campaign_buttons}>
              <div
                className={styles.create_campaign_button}
                onClick={handleCreate}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <AddIcon
                  color={hover ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 1)"}
                  width={15}
                  height={15}
                />
                Export Contacts
              </div>
              <div
                className={styles.create_campaign_button}
                onClick={handleCreate}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <AddIcon
                  color={hover ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 1)"}
                  width={15}
                  height={15}
                />
                Add Contact
              </div>
            </div>
          </div>
          <div className={styles.campaign_header}>
            <div className={styles.search_container}>
              <SearchIcon width={20} height={20} />
              <input
                className={styles.search_container_input}
                placeholder="Search contacts by name or email..."
              ></input>
            </div>
            <ContactListControls />
          </div>

          {tileView ? (
            <div className={styles.list}>
              {contacts_data.map((contact_item) => (
                <ListTileLarge contact_item={contact_item} />
              ))}
            </div>
          ) : (
            <>
              <div className={styles.table_header}>
                <div className={styles.table_title}>Name</div>
                <div className={styles.table_title}>Company</div>
                <div className={styles.table_title}>Role</div>
                <div className={styles.table_title}>Location</div>
                <div className={styles.table_title}>Industry</div>
              </div>
              <div className={styles.list}>
                {contacts_data.map((contact_item) => (
                  <ListTile contact_item={contact_item} />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
