import React, { useState, useContext, useEffect } from "react";
import styles from "./embedding.module.css";
import { AdminContext } from "../../../context/AdminContext";
import typeReply from "../../../helpers/typeReply";

const AdminEmbedding = () => {
  const [promptToEmbed, setPromptToEmbed] = useState("");
  const [embeddingResult, setEmbeddingResult] = useState("");
  const [loading, setLoading] = useState(false);

  const { generateEmbedding } = useContext(AdminContext);

  const handleGenerateEmbedding = async () => {
    if (!promptToEmbed) return;

    setLoading(true);
    setEmbeddingResult("");

    try {
      const embedding = await generateEmbedding(promptToEmbed);

      if (embedding) {
        const embeddingString = `Generated Embedding: [${embedding.join(
          ", "
        )}]`;
        typeReply(embeddingString, setEmbeddingResult);
      } else {
        setEmbeddingResult("Failed to generate embedding. Please try again.");
      }
    } catch (error) {
      console.error("Error generating embedding:", error);
      setEmbeddingResult(
        "Error generating embedding. Check the console for details."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Embedding</p>
      <div className={styles.enter_phrase}>
        <input
          className={styles.phrase_input}
          placeholder="Enter a prompt to embed"
          onChange={(event) => setPromptToEmbed(event.target.value)}
          value={promptToEmbed}
          disabled={loading}
        />
        <div
          className={`${styles.embed_button} ${
            loading ? styles.disabled_button : ""
          }`}
          onClick={!loading ? handleGenerateEmbedding : undefined}
        >
          {loading ? "Loading..." : "Embed"}{" "}
        </div>
      </div>
      <div className={styles.embed_result}>{embeddingResult}</div>
    </div>
  );
};

export default AdminEmbedding;
