import React from "react";
import styles from "./funnels.module.css";
import Switch from "../UniversalComponents/Switch/Switch";

function FunnelTile({ funnel }) {
  return (
    <div className={styles.play_tile}>
      <div className={styles.play_title_background}>
        <p className={styles.play_title}>Funnel Title</p>
        <p className={styles.play_subtitle}>
          This is a campaign for blank blank blank
        </p>
      </div>

      {/* <div className={styles.play_sequence}></div> */}
      <p className={styles.funnel_timeframe}>Runs every Monday @ 9am</p>
      <p className={styles.funnel_play}>Play: SRE Recruitment Sequence</p>
      <div className={styles.play_stats}>
        <div className={styles.play_stat}>
          <p className={styles.play_stat_title}>Captured Contacts</p>
          <p className={styles.play_stat_value}>535</p>
        </div>
        <Switch />
        {/* <div className={styles.play_stat}>
          <p className={styles.play_stat_title}>Responses</p>
          <p className={styles.play_stat_value}>6</p>
        </div>
        <div className={styles.play_stat}>
          <p className={styles.play_stat_title}>Enrolled</p>
          <p className={styles.play_stat_value}>4</p>
        </div> */}
      </div>
    </div>
  );
}

export default FunnelTile;
