import React from "react";
import { useState } from "react";
import styles from "./tasks.module.css";
import SearchbarHeader from "../UniversalComponents/SearchbarHeader/SearchbarHeader";
import { task_data } from "../../../data/task_data";
import AddIcon from "../../../assets/SVG/AddIcon";
import TaskTile from "./TaskTile";
import { useNavigate } from "react-router";

function TasksComponent() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Today");
  const tabs = ["Today", "Overdue", "Completed"];

  const [hover, setHover] = useState(false);

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Tasks</p>
      <div
        className={styles.create_campaign_button}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <AddIcon
          color={hover ? "rgba(0, 0, 0, 1)" : "rgba(255, 255, 255, 1)"}
        />
        Create Task
      </div>
      <div className={styles.slider_layout}>
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`${styles.tab} ${activeTab === tab && styles.active}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </div>
        ))}
      </div>
      {/* <SearchbarHeader /> */}
      <div className={styles.table_header}>
        <p className={styles.table_title}>Task</p>
        <p className={styles.table_title}>Contact</p>
        <p className={styles.table_title}>Due Date</p>
        <p className={styles.table_title}>Priority</p>
        {/* <p className={styles.table_title}>Task</p> */}
      </div>
      <div className={styles.task_list}>
        {task_data.map((task, index) => {
          return <TaskTile task={task} />;
        })}
      </div>
    </div>
  );
}

export default TasksComponent;
