import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";

import Funnels from "../../components/PortalComponents/FunnelsComponents/Funnels";

const FunnelsPage = () => {
  return (
    <>
      <Sidebar childComponent={<Funnels />} activeText="Funnels & Flows" />
    </>
  );
};

export default FunnelsPage;
