import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

import { AuthContext } from "./AuthContext";
import { toast } from "react-toastify";

import { db, artemisDatabase } from "../services/firebase";
import { storage } from "../services/firebase";

import {
  query,
  orderBy,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  where,
  writeBatch,
  onSnapshot,
  deleteDoc,
  runTransaction,
  limit,
  addDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

export const AdminContext = createContext();

const prompt =
  "You are an AI named Artemis, talking to your creator Justin. You have a bit of a sense of humour, and are witty, and you are also refined and cool. Your job is as follows. Sound normal and sometimes be witty. Your also sort of like Jarvis from iron man. Dont talk to much either, and dont sound too robotic at all. Dont say greetings, or assist. Dont act like a normal AI, act as if you are not a normal AI, yet more a friend and human. You are a gentelman, and be sure to refer to those you are talking to as sir. Most importantly. You are integrated in to a software and your job is to be an AI Business Development Represenative.";

export const AdminProvider = ({ children }) => {
  const { profile } = useContext(AuthContext);

  const [allUsers, setAllUsers] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [frames, setFrames] = useState([]);

  const fetchAllUsers = async () => {
    try {
      const accountsRef = collection(db, "Accounts");
      const querySnapshot = await getDocs(accountsRef);

      const users = [];
      querySnapshot.forEach((doc) => {
        users.push({ ...doc.data(), id: doc.id });
      });
      console.log(allUsers);
      console.log("Fetched all users successfully");
      setAllUsers(users);
      return {
        success: true,
        users,
      };
    } catch (error) {
      console.error("Error fetching users:", error);
      return {
        success: false,
        message: "Failed to fetch users",
        error,
      };
    }
  };

  async function sendArtemisTestingMessage(userMessage) {
    const newMessageTimestamp = Date.now();

    const message = {
      content: userMessage,
      time_stamp: newMessageTimestamp,
      sender: "Admin",
    };

    const updatedConversation = [...conversation, message];
    setConversation(updatedConversation);

    try {
      const response = await axios.post(
        "https://artemis--ai.uc.r.appspot.com/artemis/artemis-chat-response",
        {
          prompt: prompt,
          message: userMessage,
          conversation: updatedConversation,
        },
        {}
      );

      setConversation((prevConversation) => [
        ...prevConversation,
        response.data.message,
      ]);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const createNewFrame = async (frameName) => {
    try {
      const intentsCollectionRef = collection(artemisDatabase, "Intents");

      const newFrameDocRef = await addDoc(intentsCollectionRef, {
        name: frameName,
        prompt: "",
        action: frameName,
        entities: [],
        phrases: [],
      });

      const newFrame = {
        id: newFrameDocRef.id,
        name: frameName,
        prompt: "",
        action: frameName,
        entities: [],
        phrases: [],
      };

      setFrames((prevFrames) => [...prevFrames, newFrame]);

      return newFrameDocRef.id;
    } catch (error) {
      console.error("Error creating new frame:", error);
      throw new Error("Failed to create new frame.");
    }
  };

  const fetchAllFrames = async () => {
    try {
      const intentsCollectionRef = collection(artemisDatabase, "Intents");

      const snapshot = await getDocs(intentsCollectionRef);

      const intentsList = await Promise.all(
        snapshot.docs.map(async (docSnapshot) => {
          const intentData = docSnapshot.data();
          const intentId = docSnapshot.id;

          const phrasesCollectionRef = collection(
            artemisDatabase,
            "Intents",
            intentId,
            "phrases"
          );

          const phrasesSnapshot = await getDocs(phrasesCollectionRef);

          const phrases = phrasesSnapshot.docs.map((phraseDoc) => ({
            id: phraseDoc.id,
            ...phraseDoc.data(),
          }));

          return {
            id: intentId,
            ...intentData,
            phrases: phrases,
          };
        })
      );

      setFrames(intentsList);

      return {
        success: true,
        data: intentsList,
        message: "Successfully fetched all intents with their phrases.",
      };
    } catch (error) {
      console.error(
        "Error fetching intents and phrases from Firestore:",
        error
      );
      return {
        success: false,
        message: "Failed to fetch intents and their phrases. Please try again.",
        error,
      };
    }
  };

  const editFramePrompt = async (intentId, newPrompt) => {
    try {
      const intentDocRef = doc(artemisDatabase, "Intents", intentId);

      await updateDoc(intentDocRef, {
        prompt: newPrompt,
      });

      setFrames((prevFrames) =>
        prevFrames.map((frame) =>
          frame.id === intentId ? { ...frame, prompt: newPrompt } : frame
        )
      );

      return { success: true, message: "Prompt updated successfully." };
    } catch (error) {
      console.error("Error updating prompt:", error);
      return { success: false, message: "Failed to update the prompt." };
    }
  };

  const addTrainingPhrase = async (intentId, newPhrase) => {
    try {
      const phrasesCollectionRef = collection(
        artemisDatabase,
        "Intents",
        intentId,
        "phrases"
      );
      const newPhraseDocRef = await addDoc(phrasesCollectionRef, {
        phrase: newPhrase,
      });

      // Update frames state with new phrase
      setFrames((prevFrames) =>
        prevFrames.map((frame) =>
          frame.id === intentId
            ? {
                ...frame,
                phrases: [
                  ...frame.phrases,
                  { id: newPhraseDocRef.id, phrase: newPhrase },
                ],
              }
            : frame
        )
      );

      return { success: true, message: "Training phrase added successfully." };
    } catch (error) {
      console.error("Error adding training phrase:", error);
      return { success: false, message: "Failed to add training phrase." };
    }
  };

  // New: Function to delete a training phrase
  const deleteTrainingPhrase = async (intentId, phraseId) => {
    try {
      const phraseDocRef = doc(
        artemisDatabase,
        "Intents",
        intentId,
        "phrases",
        phraseId
      );
      await deleteDoc(phraseDocRef);

      // Update frames state with phrase removed
      setFrames((prevFrames) =>
        prevFrames.map((frame) =>
          frame.id === intentId
            ? {
                ...frame,
                phrases: frame.phrases.filter(
                  (phrase) => phrase.id !== phraseId
                ),
              }
            : frame
        )
      );

      return {
        success: true,
        message: "Training phrase deleted successfully.",
      };
    } catch (error) {
      console.error("Error deleting training phrase:", error);
      return { success: false, message: "Failed to delete training phrase." };
    }
  };

  async function generateEmbedding(text) {
    try {
      const response = await axios.post(
        "https://artemis--ai.uc.r.appspot.com/artemis/create-embedding",
        {
          text,
        }
      );

      return response.data.embedding;
    } catch (error) {
      console.error("Error generating embedding:", error);
      return null;
    }
  }

  const addEntity = async (intentId, newEntity) => {
    try {
      const intentDocRef = doc(artemisDatabase, "Intents", intentId);

      // Update the entity array in Firestore
      await updateDoc(intentDocRef, {
        entities: arrayUnion(newEntity),
      });

      // Update the frame state in the context
      setFrames((prevFrames) =>
        prevFrames.map((frame) =>
          frame.id === intentId
            ? { ...frame, entities: [...frame.entities, newEntity] }
            : frame
        )
      );

      return { success: true, message: "Entity added successfully." };
    } catch (error) {
      console.error("Error adding entity:", error);
      return { success: false, message: "Failed to add entity." };
    }
  };

  const removeEntity = async (intentId, entityToRemove) => {
    try {
      const intentDocRef = doc(artemisDatabase, "Intents", intentId);

      // Remove the entity from Firestore
      await updateDoc(intentDocRef, {
        entities: arrayRemove(entityToRemove),
      });

      // Update the frame state in the context
      setFrames((prevFrames) =>
        prevFrames.map((frame) =>
          frame.id === intentId
            ? {
                ...frame,
                entities: frame.entities.filter(
                  (entity) => entity !== entityToRemove
                ),
              }
            : frame
        )
      );

      return { success: true, message: "Entity removed successfully." };
    } catch (error) {
      console.error("Error removing entity:", error);
      return { success: false, message: "Failed to remove entity." };
    }
  };

  async function syncFrameToPinecone(frameId) {
    try {
      const response = await axios.post(
        "https://artemis--ai.uc.r.appspot.com/admin/sync-to-pinecone",
        { frame_id: frameId }
      );
      return response.data;
    } catch (error) {
      console.error("Error syncing frame to Pinecone:", error);
      return { success: false, message: "Failed to sync frame to Pinecone." };
    }
  }

  useEffect(() => {
    fetchAllUsers();
    fetchAllFrames();
  }, []);

  return (
    <AdminContext.Provider
      value={{
        conversation,
        setConversation,
        sendArtemisTestingMessage,
        generateEmbedding,
        frames,
        createNewFrame,
        editFramePrompt,
        addTrainingPhrase,
        deleteTrainingPhrase,
        syncFrameToPinecone,
        addEntity,
        removeEntity,
        allUsers,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
