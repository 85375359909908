import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./global_database.module.css";
import GlobalDatabaseFilters from "./GlobalDatabaseFilters";
import contacts_data from "../../../data/contacts_data";
import ListTile from "../ListsComponents/ListTile";
import ListTileLarge from "../ListsComponents/ListTileLarge";
import BackIcon from "../../../assets/SVG/BackIcon";

export default function GlobalDatabaseComponent() {
  const tileView = true;

  return (
    <div className={styles.main_layout}>
      <GlobalDatabaseFilters />
      <div className={styles.main_header_layout}>
        <div className={styles.top_header_controls}>
          <div>
            <p className={styles.accounts_found_number}>43.4k people found</p>
            <div className={styles.header_buttons}>
              <div className={styles.header_button}>Create Campaign</div>
              <div className={styles.header_button}>Export All</div>
              <div className={styles.header_button}>Save to List</div>
            </div>
          </div>

          <div className={styles.pagination_layout}>
            <div className={styles.arrow_controls}>
              <div className={styles.arrow}>
                <BackIcon color="rgba(0, 0, 0, 1)" width={12} height={12} />
              </div>
              <div
                className={styles.arrow}
                style={{ transform: "rotate(180deg)" }}
              >
                <BackIcon color="rgba(0, 0, 0, 1)" width={12} height={12} />
              </div>
            </div>
            <p className={styles.pagination_information}>1-100 of 1000</p>
          </div>
        </div>
        {tileView ? (
          <div className={styles.list}>
            {contacts_data.map((contact_item) => (
              <ListTileLarge contact_item={contact_item} />
            ))}
          </div>
        ) : (
          <>
            <div className={styles.table_header}>
              <div className={styles.table_title}>Name</div>
              <div className={styles.table_title}>Company</div>
              <div className={styles.table_title}>Role</div>
              <div className={styles.table_title}>Location</div>
              <div className={styles.table_title}>Industry</div>
            </div>
            <div className={styles.list}>
              {contacts_data.map((contact_item) => (
                <ListTile contact_item={contact_item} />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
