import React from "react";
import { useState } from "react";
import styles from "./tasks.module.css";
import SearchbarHeader from "../UniversalComponents/SearchbarHeader/SearchbarHeader";
import playbook_data from "../../../data/playbook_data";
import AddIcon from "../../../assets/SVG/AddIcon";
import { useNavigate } from "react-router";
import Checkbox from "../UniversalComponents/Checkbox/Checkbox";

function TaskTile({ task }) {
  const [completed, setCompleted] = useState(false);

  let tag_color;
  let tag_primary;

  switch (task.priority) {
    case "High":
      tag_primary = "rgba(30, 120, 50, 1)";
      tag_color = "rgba(30, 120, 50, 0.1)";
      break;
    case "Medium":
      tag_primary = "rgba(0, 30, 150, 1)";
      tag_color = "rgba(0, 30, 150, 0.1)";
      break;
    case "Low":
      tag_primary = "rgba(0, 0, 0, 1)";
      tag_color = "rgba(0, 0, 0, 0.1)";
      break;
    default:
      tag_color = "defaultColor";
      break;
  }

  return (
    <div className={styles.task_tile}>
      <div className={styles.table_row}>
        <div className={styles.task_info}>
          <div className={styles.checkbox_field}>
            <input
              type="checkbox"
              className={styles.ui_checkbox}
              checked={completed}
              //   onClick={stopPropogation}
              //   onChange={handleCheckboxClick}
            />
          </div>
          {/* <div className={styles.tile_type_icon}></div> */}
          <p className={styles.task_title}>{task.title}</p>
        </div>
        <div className={styles.contact_information}>
          <img className={styles.contact_image} src={task.contact_image} />
          <div className={styles.contact_text_info}>
            <p className={styles.table_title}>{task.contact_name}</p>
            <p className={styles.table_title}>
              {task.contact_position} at {task.contact_company}
            </p>
          </div>
        </div>

        <p className={styles.table_title}>{task.dueDate}</p>
        <p className={styles.table_title}>
          <div
            className={styles.priority_tag}
            style={{
              backgroundColor: tag_color,
              color: tag_primary,
              border: "1px solid " + tag_primary,
            }}
          >
            {task.priority}
          </div>
        </p>
      </div>
    </div>
  );
}

export default TaskTile;
