import React from "react";
import { useContext, useState, useEffect } from "react";
import styles from "./adminusers.module.css";
// import SearchBarHeader from "../../PortalComponents/UniversalComponents/search_bar_header";
import SearchbarHeader from "../../PortalComponents/UniversalComponents/SearchbarHeader/SearchbarHeader";
import UserCard from "./adminUserCard";
import { AdminContext } from "../../../context/AdminContext";
import { all } from "axios";

function AdminUsersPage() {
  const { allUsers } = useContext(AdminContext);
  const [filteredUsers, setFilteredUsers] = useState(allUsers);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const filtered = allUsers.filter((user) =>
      user?.firstname?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [searchTerm, allUsers]);

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Artemis Users</p>
      <SearchbarHeader
        type="AdminUsers"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <div className={styles.column_titles}>
        <p className={styles.column_title}>Basic Info</p>
        <p className={styles.column_title}>Account Created</p>
        <p className={styles.column_title}>Subscription Type</p>
        <p className={styles.column_title_last}>Messages Sent</p>
      </div>
      {allUsers.length !== 0 && (
        <div className={styles.prospect_scroll}>
          {allUsers.map((profile, index) => {
            return <UserCard key={index} profile={profile} />;
          })}
          <div className={styles.buffer}></div>
        </div>
      )}
    </div>
  );
}

export default AdminUsersPage;
