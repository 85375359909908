import React from "react";

const ImageIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="fi_1687806"
    viewBox="0 0 512 512"
    width={width}
    height={height}
  >
    <g>
      <path
        fill={color}
        d="M464.867,48.2H47.133C21.647,48.2,0,68.758,0,95.333v321.334C0,443.243,21.645,463.8,47.133,463.8h417.733   c25.487,0,47.133-20.558,47.133-47.133V95.333C512,68.757,490.355,48.2,464.867,48.2z M30,95.333   C30,85.758,37.762,78.2,47.133,78.2h417.733c9.366,0,17.133,7.552,17.133,17.133v188.72l-70.793-70.794   c-2.813-2.813-6.628-4.394-10.606-4.394s-7.793,1.58-10.606,4.394L272.067,331.188l-53.661-53.66   c-5.857-5.857-15.355-5.857-21.213,0L41.785,432.935C34.989,430.702,30,424.341,30,416.667V95.333z M464.867,433.8H83.346   L207.8,309.347l53.661,53.66c5.857,5.857,15.355,5.857,21.213,0L400.6,245.079l81.4,81.4v90.188   C482,426.242,474.238,433.8,464.867,433.8z"
      ></path>
      <path
        fill={color}
        d="M127.467,238.866c34.849,0,63.2-28.351,63.2-63.199s-28.351-63.2-63.2-63.2s-63.2,28.352-63.2,63.2   S92.618,238.866,127.467,238.866z M127.467,142.467c18.307,0,33.2,14.894,33.2,33.2c0,18.306-14.893,33.199-33.2,33.199   s-33.2-14.894-33.2-33.199C94.267,157.36,109.16,142.467,127.467,142.467z"
      ></path>
    </g>
  </svg>
);

export default ImageIcon;
