import DashboardIcon from "../../assets/SVG/SidebarIcons/DashboardIcon";
import TalkToAiIcon from "../../assets/SVG/SidebarIcons/TalkToAiIcon";
import SearchIcon from "../../assets/SVG/SidebarIcons/SearchIcon";
import CampaignIcon from "../../assets/SVG/SidebarIcons/CampaignIcon";
import AdminIcon from "../../assets/SVG/SidebarIcons/AdminIcon";
import InboxIcon from "../../assets/SVG/SidebarIcons/InboxIcon";
import PhoneIcon from "../../assets/SVG/SidebarIcons/PhoneIcon";
import FundraiseIcon from "../../assets/SVG/SidebarIcons/FundraiseIcon";
import IntegrationsIcon from "../../assets/SVG/SidebarIcons/IntegrationsIcon";
import ContactsIcon from "../../assets/SVG/SidebarIcons/ContactsIcon";
import SettingsIcon from "../../assets/SVG/SidebarIcons/SettingsIcon";
import SavedIcon from "../../assets/SVG/SidebarIcons/SavedIcon";
import FunnelIcon from "../../assets/SVG/SidebarIcons/FunnelIcon";
import PlaybookIcon from "../../assets/SVG/SidebarIcons/PlaybookIcon";
import ProspectsIcon from "../../assets/SVG/SidebarIcons/ProspectsIcon";
import GlobalDatabaseIcon from "../../assets/SVG/SidebarIcons/GlobalDatabaseIcon";
import TasksIcon from "../../assets/SVG/SidebarIcons/TasksIcon";

import CalendarIcon from "../../assets/SVG/SidebarIcons/CalendarIcon";

import SocialMediaDatabaseIcon from "../../assets/SVG/SidebarIcons/SocialMediaDatabaseIcon";
import LinkedinDatabaseIcon from "../../assets/SVG/SidebarIcons/LinkedinDatabaseIcon";

const main_menu = [
  // {
  //   id: 0,
  //   title: "Admin",
  //   Icon: AdminIcon,
  //   iconKey: "stroke",
  //   url: "/admin",
  //   isSelf: true,
  // },

  {
    id: 1,
    title: "Dashboard",
    Icon: DashboardIcon,
    iconKey: "stroke",
    url: "/dashboard",
    isSelf: true,
  },

  {
    id: 2,
    title: "Ask Artemis",
    Icon: TalkToAiIcon,
    iconKey: "stroke",
    url: "/ask-artemis",
    isSelf: true,
  },
  {
    id: 4,
    title: "Your Inboxes",
    Icon: InboxIcon,
    iconKey: "stroke",
    url: "/inbox",
    isSelf: true,
  },
  {
    id: 3,
    title: "Artemis Outreach",
    Icon: CampaignIcon,
    iconKey: "stroke",
    url: "/artemis-outreach",
    isSelf: true,
  },

  {
    id: 5,
    title: "Global Database",
    Icon: GlobalDatabaseIcon,
    iconKey: "stroke",
    url: "/global-database",
    isSelf: true,
  },
  {
    id: 6,
    title: "Social Media Database",
    Icon: SocialMediaDatabaseIcon,
    iconKey: "stroke",
    url: "/social-media-database",
    isSelf: true,
  },
  {
    id: 7,
    title: "Contacts",
    Icon: ProspectsIcon,
    iconKey: "stroke",
    url: "/contacts",
    isSelf: true,
  },
  {
    id: 3,
    title: "Funnels & Flows",
    Icon: FunnelIcon,
    iconKey: "stroke",
    url: "/funnels-&-flows",
    isSelf: true,
  },

  {
    id: 8,
    title: "Playbook",
    Icon: PlaybookIcon,
    iconKey: "stroke",
    url: "/playbook",
    isSelf: true,
  },

  {
    id: 8,
    title: "Tasks",
    Icon: TasksIcon,
    iconKey: "stroke",
    url: "/tasks",
    isSelf: true,
  },

  // {
  //   id: 9,
  //   title: "Auto Dialer",
  //   Icon: PhoneIcon,
  //   iconKey: "stroke",
  //   url: "/inbox",
  //   isSelf: true,
  // },

  {
    id: 10,
    title: "Meetings",
    Icon: CalendarIcon,
    iconKey: "stroke",
    url: "/meetings",
    isSelf: true,
  },

  {
    id: 11,
    title: "Integrations",
    Icon: IntegrationsIcon,
    iconKey: "stroke",
    url: "/integrations",
    isSelf: true,
  },

  {
    id: 12,
    title: "Settings",
    Icon: SettingsIcon,
    iconKey: "stroke",
    url: "/settings",
    isSelf: true,
  },
];

export default main_menu;

// {
//   id: 6,
//   title: "Linkedin Sales Navigator",
//   Icon: LinkedinDatabaseIcon,
//   iconKey: "stroke",
//   url: "/linkedin-database",
//   isSelf: true,
// },

// {
//   id: 7,
//   title: "Feedback",
//   icon_image: "/images/SidebarIcons/SidebarFeedback.png",
//   iconKey: "stroke",
//   url: "/feedback",
//   isSelf: true,
// },
