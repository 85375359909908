import React, { useState } from "react";
import styles from "../meetings.module.css";
import { meetings_data } from "../../../../data/meetings_data";
import BackIcon from "../../../../assets/SVG/BackIcon";

function Meetings() {
  const days = getCurrentWeekDays();
  return (
    <div className={styles.meeting_page_layout}>
      <div className={styles.all_meetings_controls}>
        <div className={styles.all_meetings_controls_left_side}>
          <div className={styles.arrow_button}>
            <BackIcon color="rgba(0, 0, 0, 1)" width={13} height={13} />
          </div>
          <p className={styles.timeframe_text}>This Week</p>
          <div
            className={styles.arrow_button}
            style={{ transform: "rotate(180deg)" }}
          >
            <BackIcon color="rgba(0, 0, 0, 1)" width={13} height={13} />
          </div>
        </div>
        <div className={styles.all_meetings_controls_side}>
          <div className={styles.create_meeting_button}>Create a Meeting</div>
        </div>
      </div>
      <div className={styles.horizontal_page_scroll}>
        {days.map((day) => (
          <div className={styles.day_tile}>
            <p className={styles.day_title}>{day.day}</p>
            <div className={styles.tile_scroll_content}>
              {getRandomMeetings().map((meeting) => (
                <div className={styles.meeting_tile}>
                  <div className={styles.meeting_information}>
                    <p className={styles.meeting_title}>{meeting.title}</p>
                    <p className={styles.meeting_time}>{meeting.subtitle}</p>
                    <div className={styles.view_meeting_button}>
                      View Meeting
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function getRandomMeetings() {
  const numberOfMeetings = Math.floor(Math.random() * 4) + 1;

  const shuffledMeetings = meetings_data.sort(() => 0.5 - Math.random());

  return shuffledMeetings.slice(0, numberOfMeetings);
}

function getCurrentWeekDays() {
  const currentDate = new Date();

  const dayOfWeek = currentDate.getDay();

  const daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

  const monday = new Date(currentDate);
  monday.setDate(currentDate.getDate() - daysToMonday);

  const dayNames = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const weekDays = [];
  for (let i = 0; i < 7; i++) {
    const day = new Date(monday);
    day.setDate(monday.getDate() + i);

    weekDays.push({
      date: day.toISOString().split("T")[0],
      day: dayNames[i],
    });
  }

  return weekDays;
}

console.log(getCurrentWeekDays());

export default Meetings;

// import React, { useState } from "react";
// import styles from "../meetings.module.css";
// import { meetings_data } from "../../../../data/meetings_data";

// function Meetings() {
//   return (
//     <div className={styles.meeting_page_layout}>
//       <div className={styles.tile_scroll_content}>
//         {meetings_data.map((meeting) => (
//           <div className={styles.meeting_tile}>
//             <div className={styles.tile_left}></div>
//             <div className={styles.tile_info}>
//               <p className={styles.task_title}>{meeting.title}</p>
//               <p className={styles.task_subtitle}>{meeting.subtitle}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Meetings;
