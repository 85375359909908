import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import ListComponents from "../../components/PortalComponents/ListsComponents/ListComponents";

const ListsPage = () => {
  return (
    <>
      <Sidebar childComponent={<ListComponents />} activeText="Contacts" />
    </>
  );
};

export default ListsPage;
