import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import AskArtemis from "../../components/PortalComponents/AskArtemisComponents/AskArtemis";

const AskArtemisPage = () => {
  return (
    <>
      <Sidebar childComponent={<AskArtemis />} activeText="Ask Artemis" />
    </>
  );
};

export default AskArtemisPage;
