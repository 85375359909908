import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCgTFcurck990mFPOveeaYW0Q7JwaOHlTI",
  authDomain: "artemis--ai.firebaseapp.com",
  projectId: "artemis--ai",
  storageBucket: "artemis--ai.appspot.com",
  messagingSenderId: "1067833405609",
  appId: "1:1067833405609:web:50a4767128bd2b4b8c39b8",
  measurementId: "G-0H61QK77M2",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const artemisDatabase = getFirestore(app, "artemis-brain");
const storage = getStorage(app);

export { app, auth, db, storage, artemisDatabase };
