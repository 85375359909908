import GmailIcon from "../assets/SVG/PlaybookIcons.js/GmailIcon";
import LinkedinIcon from "../assets/SVG/PlaybookIcons.js/LinkedinIcon";

const playbook_data = [
  {
    title: "Recent Conversation",
    subtitle:
      "A prospect has downloaded content from your website and you want to reach out.",
    message_sequence: [
      {
        type: "LinkedIn",
        body: "Hi there, I noticed you are in the industry and would love to connect and learn more about what you’re working on.",
      },
      {
        id: 1,
        type: "Email",
        subject: "Welcome to our community!",
        body: "Hello, we are excited to have you on board. Let us know if there’s anything we can do to assist you.\n\nJustin Cose",
      },
      {
        type: "LinkedIn",
        body: "Hi there, I noticed you are in the industry and would love to connect and learn more about what you’re working on.",
      },
      {
        type: "WhatsApp",
        body: "Hey, just wanted to check in and see how things are going with our product. Feel free to reach out if you have any questions!",
      },
      {
        type: "Messenger",
        body: "Hello! We have some exciting updates coming soon. Stay tuned!",
      },
      {
        type: "Instagram",
        body: "We just launched a new feature! Check out our latest post for more details. 💡",
      },
    ],
    runs: 42,
    responses: 42,
    enrolled: 42,
  },
  {
    title: "Trade Show or Conference Follow Up",
    subtitle:
      "A sequence designed to follow-up with a prospect you met at a trade show or conference.",
    message_sequence: [
      {
        id: 1,
        type: "Email",
        subject: "Welcome to our community!",
        body: "Hello, we are excited to have you on board. Let us know if there’s anything we can do to assist you.\n\nJustin Cose",
      },
      {
        id: 1,
        type: "Email",
        subject: "Welcome to our community!",
        body: "Hello, we are excited to have you on board. Let us know if there’s anything we can do to assist you.\n\nJustin Cose",
      },
      {
        type: "LinkedIn",
        body: "Hi there, I noticed you are in the industry and would love to connect and learn more about what you’re working on.",
      },
      {
        type: "WhatsApp",
        body: "Hey, just wanted to check in and see how things are going with our product. Feel free to reach out if you have any questions!",
      },
      {
        type: "Messenger",
        body: "Hello! We have some exciting updates coming soon. Stay tuned!",
      },
      {
        type: "Instagram",
        body: "We just launched a new feature! Check out our latest post for more details. 💡",
      },
    ],
    runs: 42,
    responses: 42,
    enrolled: 42,
    message_sequence: [
      {
        type: "LinkedIn",
        body: "Hi there, I noticed you are in the industry and would love to connect and learn more about what you’re working on.",
      },
      {
        type: "LinkedIn",
        body: "Hi there, I noticed you are in the industry and would love to connect and learn more about what you’re working on.",
      },
      {
        type: "LinkedIn",
        body: "Hi there, I noticed you are in the industry and would love to connect and learn more about what you’re working on.",
      },
      {
        type: "Messenger",
        body: "Hello! We have some exciting updates coming soon. Stay tuned!",
      },
      {
        type: "Instagram",
        body: "We just launched a new feature! Check out our latest post for more details. 💡",
      },
    ],
    runs: 42,
    responses: 42,
    enrolled: 42,
  },
  {
    title: "Product or Demo Request",
    subtitle:
      "A prospect has indicated that they want to learn more about your product.",
    message_sequence: [
      {
        type: "Instagram",
        body: "We just launched a new feature! Check out our latest post for more details. 💡",
      },
      {
        type: "Messenger",
        body: "Hello! We have some exciting updates coming soon. Stay tuned!",
      },
      {
        type: "Messenger",
        body: "Hello! We have some exciting updates coming soon. Stay tuned!",
      },
      {
        type: "Instagram",
        body: "We just launched a new feature! Check out our latest post for more details. 💡",
      },
      {
        type: "Instagram",
        body: "We just launched a new feature! Check out our latest post for more details. 💡",
      },
    ],
    runs: 42,
    responses: 42,
    enrolled: 42,
  },
  {
    title: "Post Call/Meeting Follow Up Sequence",
    subtitle:
      "Follow up email with your prospect after the initial discovery meeting has been completed.",
    message_sequence: [
      {
        id: 1,
        type: "Email",
        subject: "Welcome to our community!",
        body: "Hello, we are excited to have you on board. Let us know if there’s anything we can do to assist you.\n\nJustin Cose",
      },
      {
        type: "LinkedIn",
        body: "Hi there, I noticed you are in the industry and would love to connect and learn more about what you’re working on.",
      },
      {
        type: "WhatsApp",
        body: "Hey, just wanted to check in and see how things are going with our product. Feel free to reach out if you have any questions!",
      },
      {
        type: "Messenger",
        body: "Hello! We have some exciting updates coming soon. Stay tuned!",
      },
      {
        type: "Instagram",
        body: "We just launched a new feature! Check out our latest post for more details. 💡",
      },
    ],
    runs: 42,
    responses: 42,
    enrolled: 42,
  },
];

export default playbook_data;

// const playbook_data = [
//   {
//     title: "Recent Conversation",
//     subtitle:
//       "A prospect has downloaded content from your website and you want to reach out.",
//     runs: 42,
//     responses: 42,
//     enrolled: 42,
//   },
//   {
//     title: "Trade Show or Conference Follow Up",
//     subtitle:
//       "A sequence designed to follow-up with a prospect you met at a trade show or conference.",
//   },
//   {
//     title: "Product or Demo Request",
//     subtitle:
//       "A prospect has indicated that they want to learn more about your product.",
//   },
//   {
//     title: "Post Call/Meeting Follow Up Sequence",
//     subtitle:
//       "Follow up email with your prospect after the initial discovery meeting has been completed.",
//   },
//   {
//     title: "Inbound Lead Nurture",
//     subtitle:
//       "Engage with leads that have shown interest through inbound marketing but haven't yet converted.",
//   },
//   {
//     title: "Cold Outreach",
//     subtitle:
//       "Introduce your product or service to potential clients who have not been previously contacted.",
//   },
//   {
//     title: "Re-engagement Sequence",
//     subtitle:
//       "Reach out to prospects who have gone quiet to see if they are still interested.",
//   },
//   {
//     title: "Customer Onboarding",
//     subtitle:
//       "Guide new customers through the onboarding process and set expectations for success.",
//   },
//   {
//     title: "Upsell/Cross-Sell",
//     subtitle:
//       "Identify opportunities to upsell or cross-sell to existing customers based on their current use of your products.",
//   },
//   {
//     title: "Win-back Campaign",
//     subtitle:
//       "Reconnect with customers or leads who have stopped engaging with your brand.",
//   },
// ];

// export default playbook_data;
