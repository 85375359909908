import React from "react";

const SocialMediaDatabaseIcon = ({
  width = 17,
  height = 17,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg width={width} height={height} viewBox="0 0 512.00186 512">
    <path
      fill={color}
      d="m358.386719 255.992188c0 56.554687-45.84375 102.398437-102.394531 102.398437-56.554688 0-102.398438-45.84375-102.398438-102.398437 0-56.550782 45.84375-102.394532 102.398438-102.394532 56.550781 0 102.394531 45.84375 102.394531 102.394532zm0 0"
    ></path>
    <path
      fill={color}
      d="m255.992188 76.796875c-98.921876.113281-179.082032 80.277344-179.195313 179.195313.019531 25.121093 5.375 49.953124 15.710937 72.847656 2.886719 6.457031 10.457032 9.347656 16.914063 6.464844 6.453125-2.886719 9.347656-10.457032 6.460937-16.914063-8.863281-19.609375-13.460937-40.875-13.484374-62.394531 0-84.828125 68.765624-153.597656 153.59375-153.597656 84.828124 0 153.59375 68.769531 153.59375 153.597656-.023438 21.519531-4.621094 42.785156-13.484376 62.394531-2.886718 6.457031.007813 14.027344 6.460938 16.914063 6.457031 2.882812 14.027344-.007813 16.914062-6.464844 10.335938-22.894532 15.691407-47.726563 15.710938-72.847656-.113281-98.917969-80.277344-179.082032-179.195312-179.195313zm0 0"
    ></path>
    <path
      fill={color}
      d="m255.992188 0c-141.058594-.320312-255.671876 113.769531-255.992188 254.824219-.117188 51.699219 15.457031 102.214843 44.664062 144.875 3.828126 5.941406 11.746094 7.65625 17.691407 3.832031 5.941406-3.828125 7.65625-11.75 3.828125-17.691406-.128906-.199219-.261719-.394532-.402344-.589844-26.148438-38.03125-40.15625-83.097656-40.179688-129.257812 0-127.242188 103.148438-230.390626 230.390626-230.390626 127.242187 0 230.394531 103.148438 230.394531 230.390626-.027344 46.160156-14.039063 91.226562-40.191407 129.257812-4.152343 5.722656-2.878906 13.726562 2.839844 17.878906 5.722656 4.152344 13.726563 2.878906 17.878906-2.839844.140626-.191406.273438-.390624.402344-.589843 79.6875-116.390625 49.933594-275.34375-66.457031-355.035157-42.660156-29.207031-93.171875-44.777343-144.867187-44.664062zm0 0"
    ></path>
    <path
      fill={color}
      d="m307.191406 383.988281h-102.398437c-56.550781 0-102.394531 45.84375-102.394531 102.394531v12.800782c0 7.070312 5.730468 12.800781 12.796874 12.800781h281.589844c7.070313 0 12.800782-5.730469 12.800782-12.800781v-12.800782c0-56.550781-45.84375-102.394531-102.394532-102.394531zm0 0"
    ></path>
  </svg>
);

export default SocialMediaDatabaseIcon;
