import React from "react";
import styles from "./funnels.module.css";
import SearchbarHeader from "../UniversalComponents/SearchbarHeader/SearchbarHeader";
import FunnelTile from "./FunnelTile";
import funnels_data from "../../../data/funnels_data";
import AddIcon from "../../../assets/SVG/AddIcon";
import { useNavigate } from "react-router";

function Funnels() {
  const navigate = useNavigate();

  function handleCreatePlay() {
    navigate("/playbook/create-play");
  }

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Funnels & Flows</p>
      {/* <SearchbarHeader /> */}
      {/* <div>
        <p>Playbook</p>
        <p>Create and manage plays to run on your outreach.</p>
      </div> */}

      <div className={styles.integration_tiles}>
        <div className={styles.new_play_tile} onClick={handleCreatePlay}>
          <AddIcon color="rgba(0, 0, 0, 1)" height={25} width={25} />
          <p className={styles.new_play_title}>Create New Funnel</p>
        </div>
        {funnels_data.map((play, index) => {
          return <FunnelTile play={play} />;
        })}
      </div>
      <p className={styles.pre_made_funnels_title}>Pre Made Flows</p>
      <div className={styles.pre_made_funnels}>
        {funnels_data.map((play, index) => {
          return (
            <div className={styles.pre_made_play_tile}>
              <div className={styles.pre_made_play_titles}>
                <p className={styles.pre_made_play_title}>
                  Engineer Recruitment
                </p>
                <p className={styles.pre_made_play_subtitle}>
                  Target engineers for your company
                </p>
              </div>
              <div className={styles.pre_made_play_button}>Try Now</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Funnels;
