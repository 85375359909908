import React from "react";
import styles from "./contact_list_controls.module.css";
import BackIcon from "../../../../assets/SVG/BackIcon";

function ContactListControls({ handlePreviousPage, handleNextPage }) {
  return (
    <div className={styles.page_controls}>
      <div className={styles.layout_type_controls}>
        <div className={styles.layout_type}></div>
        <div className={styles.layout_type}></div>
      </div>
      <div></div>
      <div className={styles.pagination_layout}>
        <div className={styles.arrow_controls}>
          <div className={styles.arrow}>
            <BackIcon color="rgba(0, 0, 0, 1)" width={12} height={12} />
          </div>
          <div className={styles.arrow} style={{ transform: "rotate(180deg)" }}>
            <BackIcon color="rgba(0, 0, 0, 1)" width={12} height={12} />
          </div>
        </div>
        <p className={styles.pagination_information}>1-100 of 1000</p>
      </div>
    </div>
  );
}

export default ContactListControls;
