import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import styles from "./admin.module.css";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import AdminUsersPage from "../../components/AdminComponents/AdminUsersPage/adminUsersPage";

import AdminAremisTesting from "../../components/AdminComponents/AdminAremisTesting/AdminAremisTesting";
import AdminFrames from "../../components/AdminComponents/AdminFrames/AdminFrames";
import AdminEntities from "../../components/AdminComponents/AdminEntities/AdminEntities";
import AdminEmbedding from "../../components/AdminComponents/AdminEmbedding/AdminEmbedding";

const AdminPage = () => {
  const menuItems = [
    {
      label: "Users",
      key: "users",
      component: AdminUsersPage,
    },
    {
      label: "Artemis Testing",
      key: "artemis-testing",
      component: AdminAremisTesting,
    },
    // {
    //   label: "Artemis Brain",
    //   key: "artemis-brain",
    //   component: AdminAremisTesting,
    // },
    {
      label: "System",
      key: "system",
      component: AdminAremisTesting,
    },
    {
      label: "Frames",
      key: "frames",
      component: AdminFrames,
    },
    {
      label: "Entities",
      key: "entities",
      component: AdminEntities,
    },
    {
      label: "Embedding",
      key: "embedding",
      component: AdminEmbedding,
    },
    {
      label: "NER Model",
      key: "entity-recognition",
      component: AdminEmbedding,
    },
    {
      label: "Vector Database",
      key: "vector-database",
      component: AdminEmbedding,
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const [activeComponentKey, setActiveComponentKey] = useState(
    menuItems[0].key
  );

  useEffect(() => {
    const activeItem = menuItems.find((item) =>
      location.pathname.includes(item.key)
    );
    if (activeItem) {
      setActiveComponentKey(activeItem.key);
    }
  }, [location]);

  const renderComponent = () => {
    const activeItem = menuItems.find(
      (item) => item.key === activeComponentKey
    );
    return activeItem ? <activeItem.component /> : null;
  };

  const handleMenuItemClick = (key) => {
    setActiveComponentKey(key);
    navigate(`/admin/${key}`);
  };

  return (
    <>
      <Sidebar
        admin={true}
        childComponent={
          <div className={styles.page_layout}>
            <div className={styles.sidebar}>
              {menuItems.map((item) => (
                <div
                  key={item.key}
                  onClick={() => handleMenuItemClick(item.key)}
                  className={
                    activeComponentKey === item.key
                      ? styles.menu_item_selected
                      : styles.menu_item
                  }
                >
                  {item.label}
                </div>
              ))}
            </div>
            <div style={{ flex: 1 }}>{renderComponent()}</div>
          </div>
        }
        activeText="Admin"
      />
    </>
  );
};

export default AdminPage;
