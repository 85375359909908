/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */

import React from "react";
import styles from "./popup.module.css";
import CircularLoader from "../../PortalComponents/UniversalComponents/CircularLoader/CircularLoader";
import AskArtemis from "../../PortalComponents/AskArtemisComponents/AskArtemis";

export function ArtemisPopup(props) {
  const {
    openModal,
    closeModal,
    actionFunction,
    desc,
    label,
    onConfirm,
    isLoading,
  } = props;

  if (!openModal) {
    return null;
  }

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const modalStyle = getModalStyle();

  const modalClass = openModal
    ? `${styles.fullscreen_modal} ${styles.open}`
    : styles.fullscreen_modal;

  return (
    <div className={modalClass} onClick={closeModal}>
      {/* <div style={modalStyle} className={styles.modalPaper}>
        <div className={styles.modal_header}></div>
        <div className={styles.modal_body}></div>
      </div> */}
      <div
      // onClick={(e) => {
      //   e.stopPropagation();
      // }}
      >
        <AskArtemis />
      </div>
    </div>
  );
}
