import React from "react";
import styles from "./playbook.module.css";
import { useState } from "react";

import PlayBookSidebar from "./PlayBookSidebar";
import PlayBuilder from "./PlayBuilder/PlayBuilderSidebar";

import message_sequence_data from "../../../data/message_sequence_data";
import Play from "./PlayBuilder/Play/Play";
import PlayBuilderSidebar from "./PlayBuilder/PlayBuilderSidebar";

import playbook_data from "../../../data/playbook_data";

import PlayActionIcon from "../../../assets/SVG/ActionIcons/PlayActionIcon.js";

function Playbook() {
  const [openPlayBuilder, setOpenPlayBuilder] = useState(false);
  const [userPlays, setUserPlay] = useState(playbook_data);
  const [selectedPlay, setSelectedPlay] = useState(null);

  return (
    <div className={styles.main_layout}>
      {openPlayBuilder ? (
        <div className={styles.play_builder_open}>
          <div className={styles.preview_play_header}>
            <div className={styles.preview_play_text}>
              <div className={styles.preview_play_title}>
                Cold Lead Sequence
              </div>
              <div className={styles.preview_play_subtitle}>
                Targeting consumer sales in Chicago with tailored marketing to
                boost engagement and drive sales growth.
              </div>
            </div>
            <div className={styles.preview_play_controls}>
              <div className={styles.preview_play_button}>Edit Play</div>
            </div>
          </div>
          <div className={styles.create_play_layout}>
            <div className={styles.preview_play}>
              <div className={styles.display_play}>
                <Play />
              </div>
            </div>
            <div className={styles.play_builder_sidebar}>
              <PlayBuilderSidebar />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.create_sidebar_layout}>
            <PlayBookSidebar
              userPlays={userPlays}
              selectedPlay={selectedPlay}
              setSelectedPlay={setSelectedPlay}
            />
          </div>
          {selectedPlay ? (
            <div className={styles.preview_play}>
              <div className={styles.preview_play_header}>
                <div className={styles.preview_play_text}>
                  <div className={styles.preview_play_title}>
                    {selectedPlay.title}
                  </div>
                  <div className={styles.preview_play_subtitle}>
                    {selectedPlay.subtitle}
                  </div>
                </div>
                <div className={styles.preview_play_controls}>
                  <div className={styles.preview_play_button}>Edit Play</div>
                </div>
              </div>
              <div className={styles.display_play}>
                <Play selectedPlay={selectedPlay} />
              </div>
            </div>
          ) : (
            <div className={styles.empty_play}>
              <PlayActionIcon
                color="rgba(0, 0, 0, 0.75)"
                height={40}
                width={40}
              />
              <div>Select a Play</div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Playbook;
