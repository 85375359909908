import React, { useState } from "react";
import styles from "./meetings.module.css";

import Meetings from "./MeetingPages/Meetings";
import SchedulingPages from "./MeetingPages/SchedulingPages";
import Manage from "./MeetingPages/Manage";

function MeetingsComponent() {
  const tabs = [
    "Meetings",
    // "Recordings & Notes",
    "Scheduling Pages",
    "Scheduling Settings",
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const renderComponent = () => {
    switch (selectedTab) {
      case "Meetings":
        return <Meetings />;
      case "Scheduling Pages":
        return <SchedulingPages />;
      case "Scheduling Settings":
        return <Manage />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.main_layout}>
      <div className={styles.page_header}>
        <p className={styles.page_title}>Meetings</p>
        <div className={styles.tabs_container}>
          {tabs.map((tab) => (
            <div
              key={tab}
              className={`${styles.tab} ${
                selectedTab === tab ? styles.active_tab : ""
              }`}
              onClick={() => setSelectedTab(tab)}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.meeting_page_component}>{renderComponent()}</div>
    </div>
  );
}

export default MeetingsComponent;
