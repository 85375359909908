import React from "react";

const TasksIcon = ({
  width = 17,
  height = 17,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_2740618"
  >
    <g id="Layer_46" data-name="Layer 46">
      <path
        fill={color}
        d="m19 2h-14a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-14a3 3 0 0 0 -3-3zm-1.29 6.69-7.46 7.94a1 1 0 0 1 -.73.31 1 1 0 0 1 -.73-.31l-2.48-2.63a1 1 0 1 1 1.45-1.37l1.76 1.87 6.73-7.16a1 1 0 0 1 1.42 0 1 1 0 0 1 .04 1.35z"
      ></path>
    </g>
  </svg>
);

export default TasksIcon;
