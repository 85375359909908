import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import CampaignsComponents from "../../components/PortalComponents/CampaignsComponents/CampaignsComponents";

const CampaignsPage = () => {
  return (
    <>
      <Sidebar
        childComponent={<CampaignsComponents />}
        activeText="Artemis Outreach"
      />
    </>
  );
};

export default CampaignsPage;
