import React from "react";

const MailboxesIcon = ({
  width = 16,
  height = 16,
  color = "rgba(0, 0, 0, 0.65)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_7493199"
    width={width}
    height={height}
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      d="m14 6.5v5.5c0 .5523-.4477 1-1 1h-11c-.5523 0-1-.4477-1-1v-5.5c0-.2761.2239-.5.5-.5.0692-.0152.1408-.0152.21 0l5.79 4 5.8-4c.066-.0138.134-.0138.2 0 .2761 0 .5.2239.5.5zm-12.75-2.58.08.08 6.17 4 6.19-4h.06c.1796-.0981.2792-.2975.25-.5 0-.2761-.2239-.5-.5-.5h-12c-.2761 0-.5.2239-.5.5.0026.1745.0978.3345.25.42z"
    ></path>
  </svg>
);

export default MailboxesIcon;
