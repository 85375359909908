import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./campaigns.module.css";
import CampaignTile from "./CampaignTile";
import { useNavigate } from "react-router-dom";
import campaigns_data from "../../../data/campaigns_data";
import RefreshIcon from "../../../assets/SVG/RefreshIcon";
import AddIcon from "../../../assets/SVG/AddIcon";
import SearchIcon from "../../../assets/SVG/SearchIcon";
import { CampaignContext } from "../../../context/CampaignContext";

export default function CampaignsComponents() {
  const [activeTab, setActiveTab] = useState("All");
  const { fetchUserCampaigns } = useContext(CampaignContext);

  // const tabs = ["Account", "Payment", "Manage Subscription"];
  const tabs = ["All", "Active", "Paused", "Completed"];
  const navigate = useNavigate();

  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    async function loadCampaigns() {
      try {
        const campaignsData = await fetchUserCampaigns();
        setCampaigns(campaignsData);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    }

    loadCampaigns();
  }, [activeTab]);

  function handleCreate() {
    navigate(`/create-campaign`);
  }

  const [hover, setHover] = useState(false);

  return (
    <div className={styles.main_layout}>
      <div className={styles.page_header}>
        <div
          className={styles.create_campaign_button}
          onClick={handleCreate}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <AddIcon
            color={hover ? "rgba(0, 0, 0, 1)" : "rgba(255, 255, 255, 1)"}
          />
          Create Campaign
        </div>
        <div>
          <p className={styles.page_title}>Outreach Overview</p>
          <p className={styles.header_content_subtitle}>
            Automate campaigns, engage prospects, and track performance with
            Artemis
          </p>
        </div>
        <div className={styles.campaign_type_slider}>
          {tabs.map((tab) => (
            <div
              key={tab}
              className={`${styles.tab} ${activeTab === tab && styles.active}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </div>
          ))}
        </div>
        <div className={styles.header_controls}>
          <div className={styles.header_dropdown}>
            <p className={styles.dropdown_title}>Sort By</p>
            <img
              src="/images/general_icons/DownArrowIcon.png"
              alt="down"
              className={styles.down_icon}
            ></img>
          </div>
          {/* <div className={styles.header_dropdown}>
            <p className={styles.dropdown_title}>Tags</p>
            <img
              src="/images/general_icons/DownArrowIcon.png"
              alt="down"
              className={styles.down_icon}
            ></img>
          </div> */}
        </div>
      </div>
      <div className={styles.campaign_header}>
        <p className={styles.list_title}>{activeTab} Campaigns</p>
        <div className={styles.right_actions}>
          <div className={styles.search_container}>
            <SearchIcon />
            <input
              className={styles.search_container_input}
              placeholder="Search Campaigns"
            ></input>
          </div>

          <div className={styles.refresh}>
            <RefreshIcon />
            Refresh
          </div>
        </div>
      </div>
      <div className={styles.campaign_list}>
        {campaigns.map((campaign) => (
          <CampaignTile initialCampaign={campaign} />
        ))}
      </div>
    </div>
  );
}
