import React from "react";

const PlaybookIcon = ({
  width = 17,
  height = 17,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_2285641"
    width={width}
    height={height}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="175.794" cy="397" r="25" fill={color}></circle>
    <path
      fill={color}
      d="m435.118 46.131c-10.391-10.402-24.21-16.131-38.912-16.131h-9.956v20c0 22.056-17.944 40-40 40h-180c-22.056 0-40-17.944-40-40v-20h-10.044c-30.293 0-54.966 24.646-55 54.939l-.411 372c-.016 14.702 5.697 28.528 16.087 38.93s24.21 16.131 38.912 16.131h280c30.294 0 54.967-24.646 55-54.939l.412-372c.016-14.703-5.697-28.529-16.088-38.93zm-314.324 350.869c0-30.327 24.673-55 55-55s55 24.673 55 55-24.673 55-55 55-55-24.673-55-55zm174.094-245.825c-5.858-5.857-5.858-15.355 0-21.213 5.857-5.857 15.355-5.857 21.213 0l24.749 24.749 24.749-24.749c5.857-5.857 15.355-5.857 21.213 0s5.858 15.355 0 21.213l-24.749 24.749 24.749 24.749c5.858 5.857 5.858 15.355 0 21.213-2.929 2.929-6.768 4.394-10.606 4.394s-7.678-1.465-10.606-4.394l-24.749-24.749-24.749 24.749c-2.929 2.929-6.768 4.394-10.606 4.394s-7.678-1.465-10.606-4.394c-5.858-5.857-5.858-15.355 0-21.213l24.749-24.749zm-133.925-16.494c5.857-5.857 15.355-5.857 21.213 0l35.481 35.481c5.858 5.857 5.858 15.355 0 21.213-2.929 2.929-6.768 4.394-10.606 4.394s-7.678-1.465-10.606-4.394l-9.794-9.794v51.806c0 18.565 11.661 35.47 29.016 42.065l87.184 33.13c28.925 10.991 48.358 39.166 48.358 70.108v58.31c0 8.284-6.716 15-15 15s-15-6.716-15-15v-58.31c0-18.565-11.66-35.47-29.015-42.065l-87.184-33.13c-28.925-10.991-48.359-39.166-48.359-70.108v-51.968l-9.794 9.794c-5.857 5.857-15.355 5.857-21.213 0s-5.858-15.355 0-21.213z"
    ></path>
    <path
      fill={color}
      d="m156.25 50c0 5.523 4.477 10 10 10h180c5.523 0 10-4.477 10-10v-40c0-5.523-4.477-10-10-10h-180c-5.523 0-10 4.477-10 10z"
    ></path>
  </svg>
);

export default PlaybookIcon;
