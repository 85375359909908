const funnels_data = [
  {
    title: "Recent Conversation",
    subtitle:
      "A prospect has downloaded content from your website and you want to reach out.",
    runs: 42,
    responses: 42,
    enrolled: 42,
  },
  {
    title: "Trade Show or Conference Follow Up",
    subtitle:
      "A sequence designed to follow-up with a prospect you met at a trade show or conference.",
    runs: 42,
    responses: 42,
    enrolled: 42,
    runs: 42,
    responses: 42,
    enrolled: 42,
  },
  //   {
  //     title: "Product or Demo Request",
  //     subtitle:
  //       "A prospect has indicated that they want to learn more about your product.",
  //     runs: 42,
  //     responses: 42,
  //     enrolled: 42,
  //   },
  //   {
  //     title: "Post Call/Meeting Follow Up Sequence",
  //     subtitle:
  //       "Follow up email with your prospect after the initial discovery meeting has been completed.",
  //     runs: 42,
  //     responses: 42,
  //     enrolled: 42,
  //   },
];

export default funnels_data;
