import React, { useState } from "react";
import styles from "../meetings.module.css";

function Manage() {
  return (
    <div className={styles.meeting_page_layout}>
      <div className={styles.settings_layout}>
        <div className={styles.settings_tile}></div>
        <div className={styles.settings_tile}></div>
        <div className={styles.settings_tile}></div>
        <div className={styles.settings_tile}></div>
      </div>
    </div>
  );
}

export default Manage;
