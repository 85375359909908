import React from "react";

const ProspectsIcon = ({
  width = 17,
  height = 17,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    version="1.1"
    id="fi_655625"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          fill={color}
          d="M209.996,0C94.203,0,0,94.202,0,209.996s94.202,209.996,209.996,209.996s209.996-94.202,209.996-209.996
                S325.79,0,209.996,0z M209.996,359.993c-82.703,0-149.997-67.294-149.997-149.997S127.293,59.999,209.996,59.999
                s149.997,67.294,149.997,149.997S292.7,359.993,209.996,359.993z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          fill={color}
          d="M498.807,435.176l-90.386-90.387c-17.031,24.988-38.643,46.601-63.632,63.632l90.386,90.387
                c17.601,17.601,46.05,17.581,63.632,0C516.407,481.207,516.387,452.757,498.807,435.176z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          fill={color}
          d="M209.996,134.997c-33.09,0-59.999,26.908-59.999,59.999c0,33.09,26.908,59.999,59.999,59.999
                c33.09,0,59.999-26.908,59.999-59.999C269.995,161.906,243.087,134.997,209.996,134.997z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          fill={color}
          d="M296.695,290.094c-3.907-13.301-11.072-25.054-20.18-35.012c-16.481,18.231-40.064,29.912-66.519,29.912
                c-26.362,0-49.879-11.594-66.353-29.712c-9.364,10.743-15.958,23.828-19.821,37.874c21.839,22.624,52.324,36.837,86.173,36.837
                c34.368,0,65.194-14.716,87.09-37.949C296.93,291.409,296.864,290.722,296.695,290.094z"
        ></path>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default ProspectsIcon;
