import React from "react";
import styles from "./switch.module.css";
import { useState } from "react";

function Switch() {
  const [isToggled, setIsToggled] = useState(true);
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div className={styles.switch_info_button}>
      <div
        className={`${styles.switch} ${isToggled ? styles.on : styles.off}`}
        onClick={handleToggle}
      >
        <div className={styles.slider}></div>
      </div>
    </div>
  );
}

export default Switch;
