import { useState, useRef, useContext } from "react";
import React from "react";
import styles from "./lists.module.css";
import { useNavigate } from "react-router-dom";

import PlayIcon from "../../../assets/SVG/PlayIcon";
import PauseIcon from "../../../assets/SVG/PauseIcon";

import CircularLoader from "../UniversalComponents/CircularLoader/CircularLoader";

import { CampaignContext } from "../../../context/CampaignContext";

import MorePopup from "../UniversalComponents/MorePopup/MorePopUp";

import { toast } from "react-toastify";

export default function ListTile({ contact_item }) {
  const [contact, setContact] = useState(contact_item);
  const [isLoadingControl, setLoadingControl] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const togglePopup = (event) => {
    event.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    { label: "Edit", action: () => console.log("Edit action") },
    { label: "Delete", action: () => console.log("Delete action") },
    // { label: "Archive", action: () => console.log("Archive action") },
  ];

  return (
    <div className={styles.contact_tile}>
      <div className={styles.table_row}>
        <div className={styles.table_value}>
          <img
            className={styles.contact_image}
            src={contact.contact_image}
          ></img>
          <div className={styles.contact_person_text}>
            <p className={styles.person_name}>{contact.name}</p>
            <p className={styles.person_email}>{contact.email}</p>
          </div>
        </div>
        <div className={styles.table_value}>
          <img
            className={styles.company_image}
            src={contact.company_image}
          ></img>
          {contact.company}
        </div>
        <div className={styles.table_value}>{contact.role}</div>
        <div className={styles.table_value}>{contact.location}</div>
        <div className={styles.table_value}>{contact.industry}</div>
        {/* <div className={styles.table_value}>
          {contact.keywords.map((keyword, idx) => ({ keyword }))}
        </div> */}
        {/* <div className={styles.table_value}>Access</div> */}
      </div>
    </div>
  );
}

function capitalizeFirstLetter(string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}
