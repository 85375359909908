import React from "react";
import { useContext, useState } from "react";
import styles from "./universal.module.css";
// import FilterMenu from "../universal_components/filter_menu";
import colorAlphaConverter from "../../../../utils/colorAlphaConverter";

function SearchbarHeader(props) {
  const [menuOpen, setMenuOpen] = useState(false);

  function handleFilterClick() {
    setMenuOpen((prevState) => !prevState);
  }

  function handleCreateClick() {
    props.actionFunction();
  }

  const searchBarBorderColor = colorAlphaConverter("rgba(0, 0, 0)", 0.75);
  const createButtonBackgroundColor = colorAlphaConverter("rgba(0, 0, 0)", 0.2);
  const createButtonBorderColor = colorAlphaConverter("rgba(0, 0, 0)", 0.75);
  const createButtonBackgroundColorHover = colorAlphaConverter(
    "rgba(0, 0, 0)",
    0.35
  );

  const plusIconColor = colorAlphaConverter("rgba(0, 0, 0)", 0.95);

  return (
    <div
      className={styles.search_layout}
      style={{
        "--searchbar-border-color": searchBarBorderColor,
        "--create-button-background-color": createButtonBackgroundColor,
        "--create-button-border-color": createButtonBorderColor,
        "--create-button-background-color-hover":
          createButtonBackgroundColorHover,
      }}
    >
      <div className={styles.search_bar}>
        <img
          className={styles.search_icon}
          src="/images/GeneralIcons/SearchIcon.png"
          alt="search"
        ></img>
        <div className={styles.search_divider}></div>
        <input
          placeholder="Search"
          className={styles.search_input}
          value={props.searchTerm}
          onChange={(e) => props.setSearchTerm(e.target.value)}
        ></input>
      </div>
      {false && (
        <div className={styles.search_filter} onClick={handleFilterClick}>
          <img
            className={styles.search_filter_icon}
            src="/images/GeneralIcons/FilterIcon.png"
            alt="filter"
          ></img>
        </div>
      )}
      {props.create && (
        <div className={styles.add_button} onClick={handleCreateClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="17"
            height="17"
            fill={plusIconColor}
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M11 2h2v20h-2z" />
            <path d="M2 11h20v2H2z" />
          </svg>
        </div>
      )}
    </div>
  );
}

export default SearchbarHeader;
