import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./playbook.module.css";
import AddIcon from "../../../assets/SVG/AddIcon";
import { ArtemisPopup } from "../../PopUpComponents/ArtemisPopup/ArtemisPopup";

export default function PlayBookSidebar({
  userPlays,
  selectedPlay,
  setSelectedPlay,
}) {
  const [openArtemis, setOpenArtemis] = useState(false);

  function handlePlayClick(play) {
    setSelectedPlay(play);
  }

  return (
    <div className={styles.saved_lists_layout}>
      <ArtemisPopup
        openModal={openArtemis}
        closeModal={() => setOpenArtemis(false)}
      />
      <p className={styles.page_title}>Playbook</p>
      <input className={styles.saved_lists_search} placeholder="Search Plays" />
      <div className={styles.create_play_tile}>
        <div className={styles.play_tile_left}>
          <div className={styles.play_tile_display_box}>
            <AddIcon color="rgba(0, 0, 0, 1)" width={20} height={20} />
          </div>
        </div>

        <div className={styles.play_tile_right}>
          <p className={styles.play_title}>Start from Scratch</p>
          <p className={styles.play_subtitle}>
            Start with a blank sequence and add your own emails and tasks.
          </p>
        </div>
      </div>
      {/* <div
        className={styles.create_play_tile}
        onClick={() => setOpenArtemis(true)}
      >
        <div className={styles.play_tile_left}>
          <div className={styles.play_tile_display_box}> </div>
        </div>

        <div className={styles.play_tile_right}>
          <p className={styles.play_title}>Have Artemis Built Your Play</p>
          <p className={styles.play_subtitle}>
            Answer a couple questions and I'll build one for you!
          </p>
        </div>
      </div> */}
      <p className={styles.sidebar_premade_title}>Your Plays</p>
      <div className={styles.play_list}>
        {userPlays.map((play, index) => (
          <div
            key={index}
            className={
              play === selectedPlay
                ? styles.play_tile_selected
                : styles.play_tile
            }
            onClick={() => handlePlayClick(play)}
          >
            {/* <div className={styles.play_tile_left}>
              <div className={styles.play_tile_display_box}> </div>
            </div> */}

            <div className={styles.play_tile_right}>
              <p className={styles.play_title}>{play.title}</p>
              <p className={styles.play_subtitle}>{play.subtitle}</p>
            </div>
            <div className={styles.view_play}>View</div>
          </div>
        ))}
      </div>
    </div>
  );
}
