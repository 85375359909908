import React from "react";
import { useState } from "react";
import styles from "./popups.module.css";
import Checkbox from "../../PortalComponents/UniversalComponents/Checkbox/Checkbox";
import CircularLoader from "../../PortalComponents/UniversalComponents/CircularLoader/CircularLoader";

const LinkMailboxPopUp = ({ isOpen, onClose, onProviderSelect, isLoading }) => {
  const modalClass = isOpen
    ? `${styles.fullscreen_modal} ${styles.open}`
    : styles.fullscreen_modal;

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [termsCheckbox, setTermsCheckbox] = useState(false);

  const mailbox_options = [
    {
      id: "GOOGLE",
      title: "Google",
      subtitle: "Gmail, GSuite, Workspace",
      icon: "/EmailProviders/search.png",
    },
    {
      id: "OUTLOOK",
      title: "Outlook",
      subtitle: "Hotmail, Live, MSN",
      icon: "/EmailProviders/outlook.png",
    },
    {
      id: "MAIL",
      title: "Other",
      subtitle: "Any Provider, IMAP",
      icon: "/EmailProviders/mail.png",
    },
  ];

  const canLink = termsCheckbox && selectedProvider;

  return (
    <div className={modalClass} onClick={onClose}>
      <div className={styles.modal_body} onClick={stopPropagation}>
        <div className={styles.modal_header}>
          <p className={styles.header_title}>Connect Your Mailbox</p>
          <p className={styles.header_subtitle}>
            Link your email with Brandlink to supercharge your marketing!
          </p>
        </div>
        <div className={styles.modal_content}>
          {mailbox_options.map((option) => (
            <div
              className={`${styles.email_option} ${
                selectedProvider === option.id
                  ? styles.email_option_selected
                  : ""
              }`}
              key={option.id}
              onClick={() => setSelectedProvider(option.id)}
            >
              <img
                className={styles.email_icon}
                src={option.icon}
                alt={`${option.title} icon`}
              />
              <p className={styles.email_title}>{option.title}</p>
              <p className={styles.email_subtitle}>{option.subtitle}</p>
            </div>
          ))}
        </div>
        <div className={styles.modal_disclaimer}>
          <Checkbox
            checked={termsCheckbox}
            onCheckboxChange={(checked) => setTermsCheckbox(checked)}
          />
          <p className={styles.disclaimer_text}>
            I accept Brandlink's Terms of Service and Privacy Policy and commit
            to not using Brandlink for sending spam, unsolicited, or harassing
            emails in contravention of any relevant laws. By selecting "Link
            mailbox" below, I understand that the business contact information
            shared from my email account with Brandlink will be utilized to
            enhance Brandlink's services as detailed in the Terms of Service.
          </p>
        </div>
        <div className={styles.modal_bottom}>
          <div
            className={
              canLink
                ? styles.link_button
                : `${styles.v} ${styles.link_button_disabled}`
            }
            onClick={canLink ? () => onProviderSelect(selectedProvider) : null}
          >
            {isLoading ? (
              <CircularLoader size="30%" color="rgba(0, 88, 220, 1)" />
            ) : (
              <>
                <img
                  src="/BrandLinkLogos/BrandLinkLogoBugBlue.png"
                  className={styles.link_icon}
                  alt="Link mailbox"
                />
                Link Mailbox
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkMailboxPopUp;
