import React from "react";
import { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import App from "./App";
import AdminPage from "./pages/Admin/AdminPage";

// import NotFound from "./pages/NotFound";

// import SignInPage from "./pages/Authentication/SignInPage";
// import SignUpPage from "./pages/Authentication/SignUpPage";

import AskArtemisPage from "./pages/Portal/AskArtemisPage";
import DashboardPage from "./pages/Portal/DashboardPage";
import CampaignsPage from "./pages/Portal/CampaignsPage";
import ManageCampaignsPage from "./pages/Portal/ManageCampaignPage";
import CreateCampaignPage from "./pages/Portal/CreateCampaignPage";
import CreatorSearchPage from "./pages/Portal/CreatorSearchPage";
import CreatorProfilePage from "./pages/Portal/CreatorProfile";
import ContactsPage from "./pages/Portal/ContactsPage";
import FundraisingPage from "./pages/Portal/FundraisingPage";
import InboxPage from "./pages/Portal/InboxPage";
import PlaybookPage from "./pages/Portal/PlaybookPage";
import FeedbackPage from "./pages/Portal/FeedbackPage";
import SettingsPage from "./pages/Settings/SettingsPage";
import SignInPage from "./pages/Authentication/SignInPage";
import ForgotPasswordPage from "./pages/Authentication/ForgotPassword";
import SignUpPage from "./pages/Authentication/SignUpPage";
import RequestAccessPage from "./pages/Authentication/RequestAccessPage";
import { AuthContext } from "./context/AuthContext";

import MeetingsPage from "./pages/Portal/MeetingsPage";

import PrivacyPolicy from "./components/LegalComponents/PrivacyPolicy";

import IntegrationsPage from "./pages/Portal/IntegrationsPage";
import ListsPage from "./pages/Portal/ListsPage";
import GlobalDatabasePage from "./pages/Portal/GlobalDatabasePage";

import CreatePlayPage from "./pages/Portal/CreatePlay";
import FunnelsPage from "./pages/Portal/Funnels";
import TasksPage from "./pages/Portal/TasksPage";

function PrivateRoute({ children }) {
  const { currentUser } = useContext(AuthContext);

  const isUserAuthenticated = localStorage.getItem("authUser") || currentUser;

  return isUserAuthenticated ? children : <Navigate to="/sign-in" />;
}

function routes() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* ROUTES FOR LANDING PAGE */}
          {/* <Route path="/" element={<App childComp={<HomePage />} />} /> */}
          <Route
            path="/joinwaitlist"
            element={<App childComp={<RequestAccessPage />} />}
          />
          <Route path="/sign-in" element={<App childComp={<SignInPage />} />} />
          <Route
            path="/request-access"
            element={<App childComp={<RequestAccessPage />} />}
          />
          <Route
            path="/forgot-password"
            element={<App childComp={<ForgotPasswordPage />} />}
          />
          <Route path="/sign-up" element={<App childComp={<SignUpPage />} />} />
          <Route
            path="/sign-up/step/:stepNum"
            element={<App childComp={<SignUpPage />} />}
          />
          <Route
            path="/privacy-policy"
            element={<App childComp={<PrivacyPolicy />} />}
          />
          {/* ROUTES FOR SIGNING IN AND SIGNING UP */}
          {/* <Route path="/signin" element={<App childComp={<SignInPage />} />} />
          <Route path="/signup" element={<App childComp={<SignUpPage />} />} /> */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <App childComp={<CreatorSearchPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/global-database"
            element={
              <PrivateRoute>
                <App childComp={<GlobalDatabasePage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/social-media-database"
            element={
              <PrivateRoute>
                <App childComp={<CreatorSearchPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/creator-profile/:id"
            element={
              <PrivateRoute>
                <App childComp={<CreatorProfilePage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <App childComp={<DashboardPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/ask-artemis"
            element={
              <PrivateRoute>
                <App childComp={<AskArtemisPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/artemis-outreach"
            element={
              <PrivateRoute>
                <App childComp={<CampaignsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-campaign/:id"
            element={
              <PrivateRoute>
                <App childComp={<ManageCampaignsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-campaign"
            element={
              <PrivateRoute>
                <App childComp={<CreateCampaignPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/fundraising"
            element={
              <PrivateRoute>
                <App childComp={<FundraisingPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/inbox"
            element={
              <PrivateRoute>
                <App childComp={<InboxPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/inbox/:id"
            element={
              <PrivateRoute>
                <App childComp={<InboxPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/saved"
            element={
              <PrivateRoute>
                <App childComp={<ContactsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/funnels-&-flows"
            element={
              <PrivateRoute>
                <App childComp={<FunnelsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/contacts"
            element={
              <PrivateRoute>
                <App childComp={<ListsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/playbook"
            element={
              <PrivateRoute>
                <App childComp={<PlaybookPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/playbook/create-play"
            element={
              <PrivateRoute>
                <App childComp={<CreatePlayPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/tasks"
            element={
              <PrivateRoute>
                <App childComp={<TasksPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/meetings"
            element={
              <PrivateRoute>
                <App childComp={<MeetingsPage />} />
              </PrivateRoute>
            }
          />

          <Route
            path="/integrations"
            element={
              <PrivateRoute>
                <App childComp={<IntegrationsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <App childComp={<SettingsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/account"
            element={
              <PrivateRoute>
                <App childComp={<SettingsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/mailboxes"
            element={
              <PrivateRoute>
                <App childComp={<SettingsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/socials"
            element={
              <PrivateRoute>
                <App childComp={<SettingsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/security"
            element={
              <PrivateRoute>
                <App childComp={<SettingsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/subscription"
            element={
              <PrivateRoute>
                <App childComp={<SettingsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/feedback"
            element={
              <PrivateRoute>
                <App childComp={<FeedbackPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/users"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/artemis-testing"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/frames"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/entities"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/embedding"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default routes;
