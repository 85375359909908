import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import TasksComponent from "../../components/PortalComponents/TasksComponents/TasksComponent";

const TasksPage = () => {
  return (
    <>
      <Sidebar childComponent={<TasksComponent />} activeText="Tasks" />
    </>
  );
};

export default TasksPage;
