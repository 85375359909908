import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import FunraiseComponents from "../../components/PortalComponents/FundraiseComponents/FunraiseComponents";

const FundraisingPage = () => {
  return (
    <>
      <Sidebar
        childComponent={<FunraiseComponents />}
        activeText="Fundraising"
      />
    </>
  );
};

export default FundraisingPage;
