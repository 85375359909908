import { useState, useRef, useEffect } from "react";
import React from "react";
import styles from "./dashboard.module.css";
import CountryChart from "./DashboardCharts/CountryChart";
import NeedleChart from "./DashboardCharts/NeedleChart";
import FilledAreaChart from "./DashboardCharts/FilledAreaChart";
import ProgressCircle from "./DashboardCharts/ProgressCircle";
import DashedLineChart from "./DashboardCharts/DashedLineChart";

import NewDashboardContent from "./NewDashboard/NewDashboardContent";

export default function DashboardComponents() {
  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Welcome back, Justin</p>
      <NewDashboardContent />
      {/* <div className={styles.dashboard_content}>
        <div className={styles.left_side}>
          <div className={styles.needle_chart}>
            <div className={styles.tile_information}>
              <p className={styles.tile_title_large}>Inbox Health</p>
            </div>
            <NeedleChart />
          </div>
          <div className={styles.line_chart}>
            <div className={styles.tile_information}>
              <p className={styles.tile_title_large}>Campaign Comparison</p>
            </div>

            <DashedLineChart />
          </div>
        </div>
        <div className={styles.right_side}>
          <div className={styles.top_stats}>
            <div className={styles.top_stat_circle}>
              <div className={styles.tile_information}>
                <p className={styles.tile_title}>Live Campaigns</p>
              </div>
              <ProgressCircle />
            </div>
            <div className={styles.top_stat}>
              <div className={styles.tile_information}>
                <p className={styles.tile_title}>Total Outreach</p>
              </div>
              <FilledAreaChart />
            </div>
          </div>
          <div className={styles.country_chart}>
            <div className={styles.tile_information}>
              <p className={styles.tile_title_large}>Your Current Reach</p>
            </div>
            <CountryChart width={600} height={400} fullSize={false} />
          </div>
        </div>
      </div> */}
    </div>
  );
}
