import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

import { AuthContext } from "./AuthContext";
import { toast } from "react-toastify";

import { artemisDatabase } from "../services/firebase";
import { storage } from "../services/firebase";

import {
  query,
  orderBy,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  where,
  writeBatch,
  onSnapshot,
  deleteDoc,
  runTransaction,
  limit,
  addDoc,
} from "firebase/firestore";

export const ArtemisContext = createContext();

const prompt =
  "You are an AI named Artemis, talking to your creator Justin. You have a bit of a sense of humour, and are witty, and you are also refined and cool. Your job is as follows. Sound normal and sometimes be witty. Your also sort of like Jarvis from iron man. Dont talk to much either, and dont sound too robotic at all. Dont say greetings, or assist. Dont act like a normal AI, act as if you are not a normal AI, yet more a friend and human. You are a gentelman, and be sure to refer to those you are talking to as sir. Most importantly. You are integrated in to a software and your job is to be an AI Business Development Represenative.";

export const ArtemisProvider = ({ children }) => {
  const { profile } = useContext(AuthContext);

  const [conversation, setConversation] = useState([]);

  async function sendArtemisMessage(userMessage) {
    const newMessageTimestamp = Date.now();

    const message = {
      content: userMessage,
      time_stamp: newMessageTimestamp,
      sender: profile.uid,
    };

    setConversation((prevConversation) => [...prevConversation, message]);

    try {
      const response = await axios.post(
        "https://artemis--ai.uc.r.appspot.com/artemis/artemis-chat-response",
        { prompt: prompt, message: userMessage, conversation },
        {}
      );

      setConversation((prevConversation) => [
        ...prevConversation,
        response.data.message,
      ]);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <ArtemisContext.Provider
      value={{
        conversation,
        setConversation,
        sendArtemisMessage,
      }}
    >
      {children}
    </ArtemisContext.Provider>
  );
};
